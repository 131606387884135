<template>
  <div v-if="isPlanRequestInProgres">
    <loading></loading>
  </div>
  <div v-else>
    <div class="container-fluid">
      <div class="plan-section-title mb-4">
        <span>{{ $t('opportunity.stepOf', {value: actualSection}) }} </span>
      </div>
      <div class="row">
        <div class="col mb-4 text-center">
          <strong class="plan-section-form-title">{{sectionTitle}}</strong>
        </div>
      </div>

      <section :key="1" v-show="actualSection === 1">

        <div class="row">
          <div class="col-12 col-lg-4 order-lg-3 plan-wrapper plan-wrapper__small">
            <div class="text-center mb-3">
              <span>{{ $t('plan.remarkable') }}</span>
            </div>
            <div>
              <opportunity-plan
                  :plan="opportunity.level"
                  :message="opportunity.message"
                  :disabled="enablePlansSection"
                  :user-plan-info="userPlansInfo"
                  :prices="prices"
                  @setPlan="opportunity.level = $event"
                  @setMessage="opportunity.message = $event"
                  @setTotal="updateTotal($event)"
                  @setAutomaticApproval="setAutoApproval($event)"
              />
            </div>
            <div>
              <div class="col text-center plan-section-discount mt-3 mb-1">
                <strong>{{ $t('plan.remarkablePlanSuggestion') }}</strong>
              </div>
              <div class="row justify-content-center mt-2">
                <div class="col col-lg-8">
                  <button
                      id="ccmc_registerAdv_seePlans"
                      @click="goToPrepaid()"
                      class="btn btn-primary ccmc__red-button mb-2 form-control"
                      type="submit"
                  >
                    {{ $t('commons.checkPlans') }}
                  </button>
                </div>
              </div>
            </div>
          </div>


          <div class="col-12 col-lg-4  order-lg-1">
            <div>
              <div>
                <car-brands v-model="opportunity.brand" :attempts="registerAttempts"></car-brands>
              </div>

              <div :class="{ 'has-error': modelError }">
                <input class="ccmc_input form-control"
                       id="model"
                       type="text"
                       v-model.trim="opportunity.model"
                       list="topmodels"
                       maxlength="60"
                       :placeholder="$t('opportunity.model') + ' ' + $t('commons.asterisk')"
                       :title="$t('opportunity.model')"
                />
                <span v-if="modelError" class="help-block">{{ $t(modelError, {value: opportunity.model}) }}</span>
              </div>

              <div :class="{ 'has-error': yearError }">
                <input class="ccmc_input form-control"
                       id="year"
                       type="number"
                       min="1900"
                       max="2025"
                       v-model.number="opportunity.year"
                       :placeholder="$t('opportunity.yearModel')"
                       :title="$t('opportunity.yearModel')"
                />
                <span v-if="yearError" class="help-block">{{ $t(yearError, {value: opportunity.year}) }}</span>
              </div>

              <div :class="{ 'has-error': priceError }">
                <currency-input id="price"
                                v-model="opportunity.price"
                                :currency="opportunity.currency"
                                :tooltip="$t('opportunity.price')"
                                :placeHolderText="$t('opportunity.price')"/>
                <span v-if="priceError" class="help-block">{{ $t(priceError, {value: opportunity.price}) }}</span>
              </div>

              <div>
                <select class="ccmc_input form-control" id="currency"
                        v-model="opportunity.currency"
                        :title="$t('commons.currency')">
                  <option value="CRC">CRC</option>
                  <option value="USD">USD</option>
                </select>
              </div>

              <div :class="{ 'has-error': transferPriceError }">
                <currency-input id="transferPrice"
                                v-model="opportunity.transferPrice"
                                :currency="opportunity.currency"
                                :title="$t('opportunity.transferPrice')"
                                :placeHolderText="$t('opportunity.transferPrice')"
                />
                <span v-if="transferPriceError"
                      class="help-block">{{ $t(transferPriceError, {value: opportunity.transferPrice}) }}</span>
              </div>

              <car-styles v-model="opportunity.style" :attempts="registerAttempts"></car-styles>
              <car-transmitions v-model="opportunity.transmissionType" :attempts="registerAttempts"></car-transmitions>
              <car-fuels v-model="opportunity.fuelType" :attempts="registerAttempts"></car-fuels>

              <div :class="{ 'has-error': motorSizeError }">
                <input class="ccmc_input form-control" id="motorSizeCC" type="number" min="45"
                       v-model.number="opportunity.motorSizeCC"
                       :title="$t('opportunity.motorSizeCC')"
                       :placeholder="$t('opportunity.motorSizeCC')">
                <span v-if="motorSizeError" class="help-block">{{
                    $t(motorSizeError, {value: opportunity.motorSizeCC})
                  }}</span>
              </div>

              <div :class="{ 'has-error': mileageError }">
                <input class="ccmc_input form-control" id="mileage" type="number" min="0" max="1000000"
                       v-model.number="opportunity.mileage"
                       :title="$t('opportunity.mileage')"
                       :placeholder="$t('opportunity.mileage')"/>
                <span v-if="mileageError" class="help-block">{{ $t(mileageError, {value: opportunity.mileage}) }}</span>
              </div>
            </div>

          </div>


          <div class="col-12 col-lg-4  order-lg-1">
            <div>
              <select class="ccmc_input form-control" id="mileageUnit"
                      v-model="opportunity.mileageUnit"
                      :title="$t('opportunity.mileageUnit.title')">
                <option value="KM">{{ $t('opportunity.mileageUnit.kilometers') }}</option>
                <option value="MI">{{ $t('opportunity.mileageUnit.miles') }}</option>
              </select>
            </div>

            <div :class="{ 'has-error': exteriorColorError }">
              <input class="ccmc_input form-control" id="exteriorColor"
                     type="text"
                     v-model="opportunity.exteriorColor"
                     list="excolours" maxlength="40"
                     :placeholder="$t('opportunity.exteriorColor') + ' *'"/>
              <span v-if="exteriorColorError"
                    class="help-block">{{ $t(exteriorColorError, {value: opportunity.exteriorColor}) }}</span>
            </div>

            <div :class="{ 'has-error': interiorColorError }">
              <input class="ccmc_input form-control" id="interiorColor"
                     type="text" v-model="opportunity.interiorColor"
                     list="incolours" maxlength="40"
                     :placeholder="$t('opportunity.interiorColor') + ' *'"/>
              <span v-if="interiorColorError"
                    class="help-block">{{ $t(interiorColorError, {value: opportunity.interiorColor}) }}</span>
            </div>

            <div>
              <input class="ccmc_input form-control"
                     id="carIdNumber"
                     type="text"
                     v-model="opportunity.carIdNumber"
                     maxlength="10"
                     :placeholder="$t('opportunity.carIdNumber')"
                     :title="$t('opportunity.carIdNumber')"/>
            </div>

            <div :class="{ 'has-error': payTaxesError }">
              <select class="ccmc_input form-control"
                      id="payTaxes"
                      v-model="opportunity.payTaxes"
                      :title="$t('opportunity.payTaxes')">
                <option value="" disabled>{{ $t('opportunity.payTaxes') }}</option>
                <option :value="true">{{ $t('commons.yes') }}</option>
                <option :value="false">{{ $t('commons.no') }}</option>
              </select>
              <span v-if="payTaxesError" class="help-block">{{ $t(payTaxesError) }}</span>
            </div>

            <div :class="{ 'has-error': numberOfPassengersError }">
              <div class="form-group">
                <select class="ccmc_input form-control"
                        id="passengersNumber"
                        v-model="opportunity.passengersNumber"
                        :title="$t('opportunity.passengersNumber')">
                  <option value="" disabled>{{ $t('opportunity.passengersNumber') }} 5</option>
                  <option :value="2">2</option>
                  <option :value="3">3</option>
                  <option :value="4">4</option>
                  <option :value="5" selected>5</option>
                  <option :value="7">7</option>
                  <option :value="888">{{ $t('opportunity.moreThan7') }}</option>
                </select>
                <span v-if="numberOfPassengersError" class="help-block">{{ $t(numberOfPassengersError) }}</span>
              </div>
            </div>

            <locations-costa-rica
                :locationsCostaRica="locationsCR"
                :attempts="registerAttempts"
                @locations-CR="handleLocationsCr($event)">
            </locations-costa-rica>

            <div :class="{ 'has-error': knownDamageError }">
            <textarea class="ccmc_input ccmc_input__textarea form-control" id="knownDamage"
                  v-model="opportunity.knownDamage"
                  :placeholder="$t('opportunity.knownDamage')"
                  maxlength="160"
                  rows="4">
            </textarea>
              <span v-if="knownDamageError" class="help-block">{{
                  $t(knownDamageError, {value: KNOWN_DAMAGE_MAX_LENGTH})
                }}</span>

            </div>
            <phones
                v-if="userPhonesLoaded"
                :phoneList="phoneList"
                :attempts="registerAttempts"
                @phone-list="updatePhoneList"
            ></phones>
          </div>
        </div>

        <section class="plan-wrapper mt-5">
          <div class="container-fluid">
            <div class="row mt-3">

              <div class="row">
                <div class="col text-center plan-section-discount mt-1 mb-1">
                  <strong>{{ $t('opportunity.uploadPhotos') }}</strong>
                </div>
              </div>


              <div class="col">
                <p v-if="remainingImages > 0"
                   class="text-center highlighted-blue">{{ $t('opportunity.landscapePhotoAdvice') }}</p>

                <file-upload v-if="remainingImages > 0"
                             :max-attachments="remainingImages"
                             uploadURL="/attachment/protected"
                             @added-images="addedImages"/>
                <div class="zthumbnail-container clearfix">
                  <thumbnail v-for="attId in attachmentIdList"
                             :key="attId" :identifier="attId"
                             :is-main="mainAttachmentId==attId"
                             @removed-image="removedImage"
                             @is-main-thumbnail="makeMainThumbnail"/>
                </div>
              </div>

            </div>
          </div>
        </section>
      </section>
      <section :key="2" v-show="actualSection === 2">
        <extras :preSelectedExtras="opportunity.extraList" :editing="true"
                @extra-selected="opportunity.extraList = $event">
        </extras>
      </section>
      <section :key="3" v-show="actualSection === 3">
        <div class="row justify-content-center gy-3">
          <div class="hidden-xs col-md-12 col-lg-5">
            <div class="miniature-wrapper">
              <opportunity-miniature
                  :opportunity="previewOpportunity"
                  :isPreview="true"/>
              <div class="step-4__miniature-label">{{ $t('opportunity.step4.mniatureLabel') }}</div>
            </div>
          </div>
        </div>

        <opportunity-payment
            v-show="displayPaymentSection"
            :total="total"
            :registerAttempts="registerAttempts"
            :isPayedByPlan="isPayedByPlan"
            @invoice-data="updateInvoice($event)"
        />

        <div class="row gy-3" :class="{ 'has-error': termsAndConditionsError }">
          <terms-conditions v-model="termsCondCheck"/>
          <span v-if="termsAndConditionsError" class="help-block">{{ $t(termsAndConditionsError) }}</span>
        </div>

        <div v-if="registerAttempts && !validateSection1()" class="alert alert-danger mt-3" role="alert">
          <span>{{ $t("error-messages.opportunityReviewAlert") }} </span>
        </div>
      </section>
      <div class="container-fluid">
        <div class="mt-4 row" :class="getClassForNextBackButtons">
          <div class="col col-md-4" v-show="displayBack">
            <button
                id="back_button"
                ref="back_button"
                @click="moveSection(-1)"
                class="btn btn-md btn-primary ccmc__red-button mt-1 mb-4 form-control"
                type="submit"
            >
              {{ $t('commons.back') }}
            </button>
          </div>


          <div class="col col-md-4" v-show="displayNext">
            <button
                id="next_button"
                ref="next_button"
                @click="moveSection(1)"
                class="btn btn-md btn-primary ccmc__red-button mt-1 mb-4 form-control"
                type="submit"
            >
              {{ $t('commons.next') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <data-list :brand='opportunity.brand'/>
  </div>
</template>

<style lang="scss">
.miniature-wrapper {
  position: sticky;
  top: 1rem;
  margin: auto;
  max-width: 400px;
}
</style>

<script>
import {Opportunity, LocationsCR, User, UserPlanInfo, Prices} from "@/entities.js";
import {isEmpty, isNumber, validPrice, isAlphanumeric, validTransferPrice} from '@/validations';
import PlanStatus from '@/components/opportunity/PlanStatus.vue';
import {mapGetters} from "vuex";
import {ApiService} from "@/rest";
import {buildUrl} from "@/helper";

import CurrencyInput from '@/components/utilities/CurrencyInput.vue';
import FileUpload from '@/components/utilities/FileUpload.vue';
import Thumbnail from '@/components/utilities/Thumbnail.vue';
import CarBrands from '@/components/opportunity/CarBrands.vue';
import CarStyles from '@/components/opportunity/CarStyles.vue';
import CarFuels from '@/components/opportunity/CarFuels.vue';
import CarTransmitions from '@/components/opportunity/CarTransmitions.vue';
import LocationsCostaRica from '@/components/utilities/LocationsCostaRica.vue';
import OpportunityMiniature from '@/components/opportunity/OpportunityMiniature.vue';
import OpportunityPayment from '@/components/opportunity/OpportunityPayment.vue';
import OpportunityPlan from '@/components/opportunity/OpportunityPlan/OpportunityPlan.vue';
import Phones from '@/components/utilities/Phones.vue';
import Extras from '@/components/opportunity/Extras.vue';
import AlertModal from '@/components/utilities/AlertModal.vue';
import DataList from '@/components/utilities/DataList.vue';
import TermsConditions from '@/components/legal/TermsConditions.vue';
import Loading from "@/components/utilities/Loading.vue";

const PLANS_INFO = {
  1: {maxPhotosAllowed: 4, planId: "FREE"},
  2: {maxPhotosAllowed: 8, planId: "BRONZE"},
  3: {maxPhotosAllowed: 12, planId: "SILVER"},
  4: {maxPhotosAllowed: 20, planId: "GOLD"}
}

export default {
  name: 'OpportunityRegister',
  components: {
    Loading,
    CurrencyInput,
    FileUpload,
    Thumbnail,
    CarBrands,
    CarStyles,
    CarFuels,
    CarTransmitions,
    DataList,
    LocationsCostaRica,
    OpportunityMiniature,
    Phones,
    Extras,
    AlertModal,
    OpportunityPayment,
    OpportunityPlan,
    PlanStatus,
    TermsConditions
  },
  data() {
    let opp;

    if (this.toUpdateOpportunity != null) {
      opp = this.toUpdateOpportunity;
    } else {
      opp = new Opportunity();
    }

    let phoneList = [];

    if (opp.phoneList && opp.phoneList.length) {
      phoneList = JSON.parse(opp.phoneList);
    }

    const tempData = {
      opportunity: opp,
      phoneList,
      userPhonesLoaded: false,
      enablePlansSection: this.toUpdateOpportunity == null && !this.isBuildingOpportunity,
      total: 0,
      termsCondCheck: false,
      locationsCR: !isEmpty(this.toUpdateOpportunity) ?
          new LocationsCR({
            province: this.toUpdateOpportunity.province,
            canton: this.toUpdateOpportunity.canton,
            district: this.toUpdateOpportunity.district
          }) :
          new LocationsCR(),
      mainAttachmentId: "",
      KNOWN_DAMAGE_MAX_LENGTH: 160,
      actualSection: 1,
      registerAttempts: 0,
      isPlanRequestInProgres: true,
      userPlanInfo : new UserPlanInfo(),
      prices : new Prices()
    };

    this.putPreselectedMainAttachmentFirst(tempData);

    return tempData;
  },
  props: {
    toUpdateOpportunity: {
      type: Object,
      default: null
    },
    isBuildingOpportunity: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters(["isLogged", "userInfo"]),
    attachmentIdList() {
      let ids = this.opportunity.attachmentIds

      if (ids) return ids.split(',')

      return []
    },
    modelError() {
      if (this.registerAttempts > 0) {
        let modelErrorMessage = 'error-messages.invalidModel';

        if (isEmpty(this.opportunity.model))
          return modelErrorMessage

        if (!isAlphanumeric(this.opportunity.model))
          return modelErrorMessage

      }
      return ''
    },
    motorSizeError() {
      if (this.registerAttempts > 0) {

        if (isEmpty(this.opportunity.motorSizeCC)) {
          this.opportunity.motorSizeCC = 0;
        } else if (!isNumber(this.opportunity.motorSizeCC)) {
          return 'error-messages.invalidMotorSizeCC';
        }
      }

      return ''
    },
    yearError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.year) || !isNumber(this.opportunity.year)) return 'error-messages.invalidYear'
      }
      return ''
    },
    mileageError() {

      if (this.registerAttempts > 0) {

        if (isEmpty(this.opportunity.mileage)) {
          this.opportunity.mileage = 0;
        } else if (!isNumber(this.opportunity.mileage)) {
          return 'error-messages.invalidMileage';
        }
      }
      return ''
    },
    priceError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.price) || !validPrice(this.opportunity.price)) return 'error-messages.invalidPrice'
      }
      return ''
    },
    transferPriceError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.transferPrice) || !validTransferPrice(this.opportunity.transferPrice)) return 'error-messages.invalidTransferPrice'
      }
      return ''
    },
    exteriorColorError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.exteriorColor)) return 'error-messages.invalidExteriorColor'
      }
      return ''
    },
    interiorColorError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.interiorColor)) return 'error-messages.invalidInteriorColor'
      }
      return ''
    },
    payTaxesError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.payTaxes)) {
          this.opportunity.payTaxes = true;
        }
      }
      return ''
    },
    numberOfPassengersError() {
      if (this.registerAttempts > 0) {
        if (isEmpty(this.opportunity.passengersNumber)) {
          this.opportunity.passengersNumber = 5;
        } else if (!isNumber(this.opportunity.passengersNumber))
          return 'error-messages.invalidPassengersNumber'
      }
      return ''
    },
    previewOpportunity() {
      let opp = Object.assign({}, this.opportunity)

      opp.preview = true

      return opp
    },
    knownDamageError() {
      if (!isEmpty(this.opportunity.knownDamage)) {
        if (this.opportunity.knownDamage.length >= this.KNOWN_DAMAGE_MAX_LENGTH) return 'error-messages.invalidKnownDamage'
      }
    },
    remainingImages() {
      const maxAttachmentsPerPlan = PLANS_INFO[this.opportunity.level].maxPhotosAllowed;
      const numberOfAttachments = Array.isArray(this.attachmentIdList) ? this.attachmentIdList.length : 0;
      return maxAttachmentsPerPlan - numberOfAttachments;
    },
    displayPaymentSection() {
      return this.opportunity && this.total > 0;
    },
    hasAvailableOppInPlan() {
      return this.opportunity && this.opportunity.level;
    },
    textToDisplayInButton() {
      if (this.displayPaymentSection) {
        return 'commons.payForOpportunity'
      }

      return 'commons.create'
    },
    termsAndConditionsError() {
      if (this.registerAttempts > 0) {
        if (!this.termsCondCheck) return 'error-messages.invalidTermsAndConditions'
      }
      return ''
    },
    isPayedByPlan() {
      let planData = PLANS_INFO[this.opportunity.level]

      let planAvailability = this.userPlansInfo[planData.planId]

      if (planAvailability !== undefined) {
        return planAvailability.availableOpportunities > 0
      }

      return false;
    },
    displayNext() {
      return this.actualSection === 1 || this.actualSection === 2;
    },
    displayBack() {
      return this.actualSection === 2 || this.actualSection === 3;
    },
    getClassForNextBackButtons(){
      if(this.actualSection === 2 || this.actualSection === 3) {
        return 'justify-content-between'
      }

      return 'justify-content-center';
    },
    sectionTitle() {
      switch (this.actualSection) {
        case 1:
          return this.$i18n.t("opportunity.descriptionCar");
        case 2:
          return this.$i18n.t("extra.extras");
        case 3:
          return this.$i18n.t("invoice.title");
        default:
          return '';
      }
    }
  },
  watch: {
    opportunity: {
      deep: true,
      handler() {
        this.emitOpportunityData();
        let text = this.textToDisplayInButton;
        this.$emit('button-text-label', text)
      }
    },
    termsCondCheck() {
      this.emitOpportunityData();
    }
  },
  methods: {
    moveSection(direction) {
      this.registerAttempts++;

      const moveToSection = () => {
        this.actualSection += direction;
        this.$emit("actual-section", this.actualSection);
      };

      if (direction > 0) {
        if (this.actualSection === 1) {
          if (this.validateSection1()) {
            moveToSection();
          }
        } else {
          moveToSection();
        }
      } else {
        moveToSection();
      }

      this.$refs.next_button.blur();
      this.$refs.back_button.blur();

    },
    goToPrepaid() {
      this.$router.push("buyPlan");
    },

    validateSection1() {
      if (isEmpty(this.opportunity.model)) return false
      if (!isEmpty(this.opportunity.motorSizeCC)) {
        if(!isNumber(this.opportunity.motorSizeCC)) return false
      }
      if (isEmpty(this.opportunity.year) || !isNumber(this.opportunity.year)) return false
      if (isEmpty(this.opportunity.mileage) || !isNumber(this.opportunity.mileage)) return false
      if (isEmpty(this.opportunity.price) || !validPrice(this.opportunity.price)) return false
      if (isEmpty(this.opportunity.transferPrice) || !validTransferPrice(this.opportunity.transferPrice)) return false
      if (isEmpty(this.opportunity.exteriorColor)) return false
      if (isEmpty(this.opportunity.interiorColor)) return false
      if (isEmpty(this.opportunity.payTaxes)) return false
      if (isEmpty(this.opportunity.passengersNumber) || !isNumber(this.opportunity.passengersNumber)) return false
      if (!this.opportunity.phoneList || this.opportunity.phoneList.length < 3) return false
      if (!isEmpty(this.opportunity.knownDamage)) {
        if (this.opportunity.knownDamage.length >= this.KNOWN_DAMAGE_MAX_LENGTH) return false
      }

      return true
    },
    validateSection3() {
      if (!this.termsCondCheck) return false;

      return true
    },
    addedImages(imageIds) {
      if (this.opportunity.attachmentIds) {
        this.opportunity.attachmentIds += ',' + imageIds
      } else {
        this.opportunity.attachmentIds = imageIds
        this.makeMainThumbnail(this.getFirstImageId(imageIds));
      }
    },
    removedImage(imageId) {
      if (this.opportunity.attachmentIds) {
        let attachmentIds = this.opportunity.attachmentIds.split(',')
        let newAttachmentsIds = attachmentIds.filter(i => i !== imageId)

        this.setFirstAttachmentAsMain(newAttachmentsIds);
        this.opportunity.attachmentIds = newAttachmentsIds.join(',')
      }
    },
    emitOpportunityData() {
      if (this.validateSection1()) {
        this.$emit('opportunity-data', this.opportunity)
      } else {
        this.$emit('opportunity-data', null)
      }
    },
    handleLocationsCr(locationsCostaRica) {
      this.opportunity.province = locationsCostaRica.province;
      this.opportunity.canton = locationsCostaRica.canton;
      this.opportunity.district = locationsCostaRica.district;
    },
    makeMainThumbnail(thumbnailId) {
      this.mainAttachmentId = thumbnailId;
      this.putMainAttachmentFirst();
    },
    putMainAttachmentFirst() {
      const unOrderedAttachmentsIds = this.opportunity.attachmentIds.split(',');

      if (this.mainAttachmentId && unOrderedAttachmentsIds.length > 0 && unOrderedAttachmentsIds.includes(this.mainAttachmentId)) {
        const orderedAttachmentsIds = unOrderedAttachmentsIds.filter(x => x !== this.mainAttachmentId);
        orderedAttachmentsIds.unshift(this.mainAttachmentId);
        this.opportunity.attachmentIds = orderedAttachmentsIds.join(',');
      }
    },
    putPreselectedMainAttachmentFirst(tempData) {
      if (tempData.opportunity && tempData.opportunity.attachmentIds) {
        const attachmentIdList = tempData.opportunity.attachmentIds.split(",");

        tempData.mainAttachmentId = attachmentIdList[0];
      }
    },
    setFirstAttachmentAsMain(attachmentIds) {
      if (attachmentIds && attachmentIds.length > 0) {
        this.mainAttachmentId = attachmentIds[0];
      }
    },
    updateInvoice(invoice) {
      this.$emit("invoice-data", invoice);
    },
    setAutoApproval(autoApprovalValue) {
      this.opportunity.autoApproval = autoApprovalValue;
    },
    updateTotal(event){
      this.total = event;
    },
    getFirstImageId(imageIds) {

      if (imageIds) {
        if (imageIds.includes(',')) {
          return imageIds.split(',')[0];
        }

        return imageIds;
      }
    },
    getUrl(resource) {
      return buildUrl(resource);
    },
    updatePhoneList(phoneList) {
      this.opportunity.phoneList = JSON.stringify(phoneList);
    },
    loadPrices() {
      ApiService.GET(
          "/opportunity/plansPrices/",(err, apiResponse) => {
            if (!err) {
              this.prices = apiResponse;
            }
          }
      );
    },
    loadPlanStatus() {
      if (this.isLogged) {
        ApiService.GET("/users/protected/planstatus", (err, apiResponse) => {
          if (!err) {
            this.userPlanInfo = apiResponse;
          }
        });
      }
    }
  },
  mounted() {
    if (!this.isLogged) {
      localStorage.setItem('afterLogin', '/opportunityRegister');
      this.$router.push("/signin");
      return;
    }

    if (this.toUpdateOpportunity != null && this.toUpdateOpportunity !== 'null') {
      this.opportunity = this.toUpdateOpportunity;
    }

    if (!this.opportunity.province || this.phoneList.length === 0) {
      // Fill province, canton and district with user data for new opportunities
      ApiService.GET('/users/protected/' + this.userInfo.id, (err, apiResponse) => {
        if (!err) {
          let user = new User(apiResponse);

          if (!this.opportunity.province) {
            this.locationsCR = new LocationsCR({
              province: user.province,
              canton: user.canton,
              district: user.district
            });
          }

          if (this.phoneList.length === 0) {
            this.phoneList = user.phoneList;
            this.updatePhoneList(user.phoneList);
          }
        }

        this.userPhonesLoaded = true;
      });
    } else {
      this.userPhonesLoaded = true;
    }

    if (this.isLogged) {
      ApiService.GET("/users/protected/planstatus", (err, apiResponse) => {
        if (!err) {
          this.userPlansInfo = apiResponse;
          this.isPlanRequestInProgres = false;
        }
      });
    }

    this.loadPrices();
    this.loadPlanStatus();
  }
}
</script>