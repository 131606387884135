<template>
  <div class="container">
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <h2>{{ $t('user.userProfile') }}</h2>
      <section class="white-box">
        <h3>{{ $t('user.personalInformation') }}</h3>
        <div class="row mb-3">
          <div class="col-md-6 col-lg-4">
            <select
                id="roleForUser"
                class="ccmc_input form-control"
                v-model="roleSelectedByAdmin"
            >
              <option
                  v-for="role of availableRoles"
                  :key="role.key"
                  :value="role.key"
              >{{ $t(role.label) }}
              </option>
            </select>
          </div>
        </div>
        <user-data ref="userData"
                   :user="user"
                   :attempts="attempts"
                   @user-data="handleUser($event)">
        </user-data>
      </section>
      <div class="row mb-3 gy-3">
          <p v-if="errorMessage" class="text-danger">
            {{ $t(errorMessage) }}
          </p>
      </div>
      <section class="button-box">
        <button type="button" class="btn btn-primary" @click="doUpdate()">{{ $t('commons.update') }}</button>
      </section>
    </template>
  </div>
</template>

<script>

const ROLES = [
  {  key: "ADVERTISER", label: "user.roles.ADVERTISER" },
  {  key: "IMPERSONALIZATOR", label: "user.roles.IMPERSONALIZATOR"},
  {  key: "TESTUSER", label: "user.roles.TESTUSER" },
  {  key: "ADMINISTRATOR", label: "user.roles.ADMINISTRATOR" }
];

  import { ApiService } from '@/rest.js'

  import { User } from '@/entities.js'
  import UserData from '@/components/user/UserData.vue'
  import CorrectDataDisclaimer from '@/components/user/CorrectDataDisclaimer.vue'
  import Loading from '@/components/utilities/Loading.vue'

  export default {
    name: 'AdminUserEditView.vue',
    components: {
      UserData,
      Loading,
      CorrectDataDisclaimer
    },    
    props: {
      userId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        user: new User(),
        attempts: 0,
        errorMessage: '',
        isRequestInProgress: true,
        roleSelectedByAdmin: 'ADVERTISER',
        availableRoles: ROLES
      }
    },
    methods: {
      loadUserData() {
        ApiService.GET('/users/protected/' + this.userId, (err, apiResponse) => {
          if (err) {
            this.errorMessage = 'error-messages.errorOccurred'
          } else {
            this.user = new User(apiResponse)
          }
          this.isRequestInProgress = false;
        });
      },
      doUpdate() {
        this.attempts++;

        if(this.user != null) {
          this.user.userRol = this.roleSelectedByAdmin;
          this.user.newPassword = this.user.password;

          let callback = (err, data) => {
            this.isRequestInProgress = false;
            if (err) {
              this.errorMessage = 'error-messages.errorOccurred'
            } else {
              this.$router.push('/adminUserMaintenanceResult')
            }
          }

          ApiService.PUT('/users/protected/adminUpdate', this.user, callback)
          this.isRequestInProgress = true;
        }
      },
      changeIsUpdating() {
        this.isUpdatingCredentials = !this.isUpdatingCredentials;
      },
      handleUser(userData) {
        this.user.userType = userData.userType;
        this.user.merchantName = userData.merchantName;
        this.user.province = userData.province;
        this.user.canton = userData.canton;
        this.user.district = userData.district;
        this.user.name = userData.name;
        this.user.lastName = userData.lastName;
        this.user.lastName2 = userData.lastName2;
        this.user.phoneList = userData.phoneList;
      }
    },
    created() {
      this.loadUserData();
    }
  }
</script>