<template>
  <div class="container-fluid mt-5 h-100">
    <div class="row justify-content-center">
      <div class="col col-md-8">
        <div class="card signin shadow rounded">
          <div class="card-header text-center signin signin__card-header pt-3">
            <h2>{{ $t("signin.tittle") }}</h2>
          </div>
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-8 col-lg-6 text-center" :class="{ 'has-error': emailError || passwordError }">
                <div v-show="!isRequestProcessing">

                    <input
                        type="text"
                        id="email"
                        class="ccmc_input form-control"
                        v-model="email"
                        maxlength="64"
                        :placeholder="$t('commons.email')"
                    />
                    <span v-if="emailError" class="help-block">{{ $t(emailError, {value: email}) }}</span>


                    <password-input
                        v-model="currentPassword"
                        input-id="currentPassword"
                        :autocomplete="'on'"
                        :placeholder="$t('registration.currentPassword')"
                    >
                    </password-input>
                    <span v-if="passwordError" class="help-block">{{
                        $t(passwordError)
                      }}</span>

                  <div class="row">
                    <div class="col text-end">
                      <p class="signin__link">
                        <a href="#" class="signin__link" id="password-recovery" @click.prevent="resetPassword()">
                          {{ $t("registration.forgotPassword") }}
                        </a>
                      </p>
                      <p v-if="signinMessage" class="text-danger">
                        {{ $t(signinMessage) }}
                      </p>
                    </div>
                  </div>

                  <div class="row justify-content-center">
                    <div class="col">
                      <button
                          id="signin"
                          @click="singin()"
                          class="btn btn-md btn-primary ccmc__red-button mt-4 form-control"
                          type="submit"
                      >
                        {{ $t("signin.signin") }}
                      </button>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col mt-5 signin__link">
                      <p>
                        {{ $t("registration.notRegistered") }}?
                        <a href="#" class="signin__link signin__link--bold" id="create-account" @click.prevent="createAccount()">{{
                            $t("registration.createAccount")
                          }}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="isRequestProcessing">
                <loading :message="'registration.login.logining'"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
// @ is an alias to /src
import {ApiService} from "@/rest.js";
import {UserInfo} from "@/entities.js";

import Loading from "@/components/utilities/Loading.vue";
import PasswordInput from "@/components/user/PasswordInput.vue";
import {validEmail, validPasswordLight} from "@/validations";

export default {
  name: "SigninView",
  components: {
    PasswordInput,
    Loading
  },
  data() {
    return {
      isRequestProcessing: false,
      signinAttempts: 0,
      signinMessage: "",
      email: "",
      currentPassword: ""
    };
  },
  computed: {
    emailError() {
      if (this.signinAttempts > 0) {
        if (!validEmail(this.email))
          return "error-messages.invalidEmail";
      }
    },
    passwordError() {
      if (this.signinAttempts > 0) {
        if (validPasswordLight(this.currentPassword).strength === 0)
          return "error-messages.invalidPassword";
      }
    },
  },
  methods: {
    displayLoading(isRequestProcessing) {
      this.isRequestProcessing = isRequestProcessing;
    },
      singin() {
      this.signinAttempts++;
      this.signinMessage = "";

      if (this.validateForm()) {
        this.isRequestProcessing = true;

        ApiService.POST(
            "/users/login",
            {
              email: this.email,
              password: this.currentPassword
            },
            (err, apiResponse) => {
              this.isRequestProcessing = false;
              if (err) {
                if (err.responseText) {
                  this.signinMessage =
                      "error-codes.errorCode_" + err.responseText;
                } else {
                  this.signinMessage = "error-messages.errorOccurred";
                }
              } else {
                localStorage.setItem("JWT", apiResponse.token);

                let userInfo = new UserInfo(apiResponse);
                userInfo.isLogged = true;
                userInfo.isAdmin = userInfo.role == "ADMINISTRATOR";

                this.$store.dispatch("setUserInfo", userInfo);
                this.$i18n.locale = userInfo.language;

                let target = localStorage.getItem('afterLogin', '/opportunityRegister');
                if (target) {
                  localStorage.removeItem('afterLogin');
                } else {
                  target = "/";
                }

                this.$router.push(target);
              }
            }
        );
      }
    },
    validateForm() {
      return validEmail(this.email) && validPasswordLight(this.currentPassword);
    },
    createAccount() {
      this.$router.push("register");
    },
    resetPassword() {
      this.$router.push("forgotPassword");
    }
  }
};
</script>
