<template>
  <div class="home">
  </div>
</template>

<script>
import Autocomplete from "@/components/search/Autocomplete.vue";
import {mapGetters} from "vuex";

export default {
  name: "Banner",
  components: {
    Autocomplete
  },
  computed: {
    ...mapGetters(["isLogged"])
  },
  methods: {
    checkOpportunities() {
      this.$router.push("searchingOpportunities");
    },
    handleRedirectForCreateOpportunity() {
      if (!this.isLogged) {
        localStorage.setItem('afterLogin', '/opportunityRegister');
        this.$router.push("/signin");
        return;
      }

      this.$router.push("/opportunityRegister");
    },
  }
};
</script>