<template>
  <div id="app">
    <common-header />
    <div id="wrapper">
      <router-view />
    </div>
    <page-footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import CommonHeader from "@/components/CommonHeader.vue";
import { mapGetters } from "vuex";
import { initFlags } from "@/utils/featureflag";
import PageFooter from "@/components/PageFooter.vue";
import {ApiService} from "@/rest";

export default {
  components: {
    PageFooter,
    CommonHeader
  },
  computed: {
    ...mapGetters(["isLogged"])
  },
  methods: {
    reLoginSteps() {
      try {
        ApiService.POST("/users/logout", {}, () => {
          this.$store.commit("clearUserInfo");
          localStorage.removeItem("JWT");
          this.$router.push("/signin");
        });
      } catch (ex) {}
    }
  },
  mounted() {
    this.$root.$on("reLogin", data => {
      this.reLoginSteps();
    });
  },
  created() {
    initFlags("");
  }
}
</script>
