<template>
  <div>
    <canvas ref="searchesDiagramRef" width="400" height="100"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
import { ChartDataset, ChartData } from "@/entities";

export default {
  name: "SearchesDiagram",
  props: {
    dashboard: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    dashboard: function() {
      this.loadCharts();
    }
  },
  methods: {
    loadCharts() {
      if (this.chart) {
        this.chart.destroy();
      }

      const chartEntries = new Map();

      this.dashboard.seriesDto.labels.forEach((count, i) => {
        chartEntries.set(this.dashboard.seriesDto.labels[i], [
          this.dashboard.seriesDto.data[i],
          0
        ]);
      });

      const triplets = [];

      chartEntries.forEach((tuple, label) => {
        triplets.push([label, ...tuple]);
      });

      const ctx = this.$refs.searchesDiagramRef.getContext("2d");
      const cfg = this.chartConfig(
        triplets.map(t => t[1]),
        triplets.map(t => t[0]).map(x => new Date(x))
      );

      this.chart = new Chart(ctx, cfg);
    },
    chartConfig(searches, labels) {
      return {
        data: {
          labels,
          datasets: [
            new ChartDataset({
              label: 'Búsquedas por Periodo',
              backgroundColor: "#f0474a",
              borderColor: "#f0474a",
              data: searches
            }),
          ]
        },
        options: {
          scales: {
            xAxes: [
              {
                type: "time",
                time: {
                  displayFormats: {
                    day: "D MMM YYYY"
                  },
                  unit: "day"
                },
                distribution: "series",
                ticks: {
                  source: "labels"
                }
              }
            ],
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t("dashboard.diagram.leftLabel")
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          }
        }
      };
    }
  },
  mounted() {
    this.loadCharts();
  }
};
</script>
