<template>
  <div class="container">
    <template v-if="isRequestInProgress">
      <loading></loading>
    </template>
    <template v-else>
      <h2>{{ $t('user.userProfile') }}</h2>
      <section class="white-box">
        <h3>{{ $t('user.personalInformation') }}</h3>
        <user-data
           :user="user"
           :attempts="attempts"
           :is-editing="true"
           :disable-email="true"
           @user-data="handleUser($event)">
        </user-data>
        <correct-data-disclaimer 
          :attempts="attempts"
          @disclaimer-agreed="agreed =$event">
        </correct-data-disclaimer>
      </section>
      <div class="row">
          <p v-if="errorMessage" class="text-danger">
            {{ $t(errorMessage) }}
          </p>
      </div>
      <section class="button-box">
        <button type="button" class="btn btn-primary" @click="doUpdate()">{{ $t('commons.update') }}</button>
      </section>
    </template>
  </div>
</template>

<script>
  import { ApiService } from '@/rest.js'

  import UserData from '@/components/user/UserData.vue'
  import CorrectDataDisclaimer from '@/components/user/CorrectDataDisclaimer.vue'
  import Loading from '@/components/utilities/Loading.vue'
  import PasswordInput from "@/components/user/PasswordInput.vue";
  import {mapGetters} from "vuex";
  import {User} from "@/entities";

  export default {
    name: 'EditMyProfileView',
    components: {
      PasswordInput,
      UserData,
      Loading,
      CorrectDataDisclaimer
    },
    data() {
      return {
        user: new User(),
        attempts: 0,
        errorMessage: '',
        isRequestInProgress: true,
        agreed: false,
      }
    },
    computed: {
      ...mapGetters(["isAdmin"])
    },
    methods: {
      loadCurrentUserData() {
        const userId = (this.$store.getters.userInfo) ? (this.$store.getters.userInfo.id) : 0;
        this.isRequestInProgress = true;
        ApiService.GET('/users/protected/' + userId, (err, apiResponse) => {
          if (err) {
            this.errorMessage = 'error-messages.errorOccurred'
          } else {
            this.user = new User(apiResponse)
          }
          this.isRequestInProgress = false;
        });
      },
      doUpdate() {
        this.attempts++;

        if(this.agreed) {
          this.user.newPassword = this.user.password;

          let callback = (err, data) => {
            this.isRequestInProgress = false;
            if (err) {
              this.errorMessage = 'error-messages.errorOccurred'
            } else {
              this.$router.push('/editMyProfileResult')
            }
          }

          ApiService.PUT('/users/protected/updateMyProfile', this.user, callback)
          this.isRequestInProgress = true;
        }
      },
      handleUser(userData) {
        this.user.userType = userData.userType;
        this.user.merchantName = userData.merchantName;
        this.user.province = userData.province;
        this.user.canton = userData.canton;
        this.user.district = userData.district;
        this.user.name = userData.name;
        this.user.lastName = userData.lastName;
        this.user.lastName2 = userData.lastName2;
        this.user.phoneList = userData.phoneList;
      }
    },
    created() {
      this.loadCurrentUserData();
    }
  }
</script>