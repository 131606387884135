export const CONTEXT_PATH = "";
export const API_URL = "/rest/api/v1";

export const MESSAGES_TITLES = {
  success: { value: "SUCCESS", label: "messagesTitle.success"},
  info: { value: "INFO", label: "messagesTitle.info"},
  warning: { value: "WARNING", label: "messagesTitle.warning"},
  error: { value: "ERROR", label: "messagesTitle.error"}
};

export const ANALITYCS_TYPES = {
  CONTACT_SELLER_BY_WHATSAPP: { value: "CONTACT_SELLER_BY_WHATSAPP" },
};
