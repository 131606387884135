export default {
  carStyles: {
    COUPE: "Coupe",
    HATCHBACK: "Hatchback",
    PICK_UP_4X2: "PickUp 4x2",
    PICK_UP_4X4: "PickUp 4x4",
    SEDAN: "Sedán",
    TODO_TERRENO_4X2: "Todo Terreno 4x2",
    TODO_TERRENO_4X4: "Todo Terreno 4x4",
    CONVERTIBLE: "Convertible",
    VAN: "Van",
    PANEL: "Panel",
    CAMION: "Camión"
  },
  commons: {
    accept: "Aceptar",
    actions: "Acciones",
    active: "Activo",
    actives: "Activos",
    addImages: "Agregar imágenes",
    addPhone: "Agregar teléfono",
    addToList: "Agregar",
    addToWishList: "Agregar a Lista",
    allUserOpportunities: "Todos los anuncios del usuario",
    amount: "Monto",
    approved: "Aprobado",
    asterisk: "*",
    back: "Regresar",
    cancel: "Cancelar",
    checkPlans: 'Comprar Anuncios Destacados',
    clear: "Limpiar",
    close: "Cerrar",
    conditions: "Condiciones",
    confirm: "Confirmar",
    continue: "Continuar",
    create: "Crear Anuncio",
    currency: "Moneda",
    currentLanguage: "Español",
    deletePhone: "Borrar teléfono",
    dateCreated: "Fecha Creación",
    details: "Detalles",
    edit: "Editar",
    email: "Correo electrónico",
    facebook: "Facebook",
    from: "Desde",
    fullName: "Nombre completo",
    goldOpportunities: "Anuncios nivel Oro",
    id: "Identificador",
    inactive: "Inactivo",
    list: "Lista",
    loading: "Cargando...",
    loading_user: "Cargando Perfil...",
    loginInformation: "Información de acceso",
    message: "Mensaje",
    miniatures: "Miniaturas",
    new: "Nuevos",
    next:'Siguiente',
    no: "No",
    noResultsFound: "No se encontraron resultados.",
    ok: "Aceptar",
    option: "Opciones",
    other: "Otro",
    phone: "Teléfono",
    plans: "Planes",
    prepaidPlans: "Comprar Anuncios Destacados",
    processing: "Procesando",
    payForOpportunity: "Pagar Anuncio",
    removeFromList: "Remover",
    request: "Consultar",
    requiredField: "Campo requerido",
    save: "Guardar",
    search: "Buscar",
    send: "Enviar",
    share: "Compartir",
    status: "Estado",
    statusSaved: "Estado Guardado",
    step: "Paso",
    thanks: "Gracias",
    to: "Hasta",
    total: "Total",
    type: "Tipo",
    update: "Actualizar",
    version: "Versión",
    view: "Ver",
    viewMore: "Ver más",
    viewDetail: "Ver Detalle",
    whatsapp: "WhatsApp",
    year: "Año",
    yes: "Sí",
    any: "(cualquiera)",
    dateFormats: {
      short : "{day}-{month}-{year}",
      shortTime : "{day}-{month}-{year} {hour}:{minutes}:{seconds}"
    },
    generalStatus :{
      ACTIVE: "Activo",
      NOT_CONFIRMED:"No Confirmado",
      BLOCKED_BY_FAILS: "Bloqueado (intentos)"
    },
    viewOpportunity: "Ver Anuncio"
  },
  company: {
    ccmc: "CualCarroMeCompro",
    url: "cualcarromecompro.com",
    luzmax: "Luzmax Soluciones S.A.",
    luzmaxJuridicalPersonId: "3-101-467489",
    luzmaxJuridicalPersonIdLabel: "Cédula Jurídica"
  },
  content : {
    buyingUsedCardTips: {
      titlePart1: "Recomendaciones para",
      titlePart2: "comprar",
      titlePart3: "su vehiculo",
      tip1 : {
        title: "Prueba de manejo",
        detail: "Procure hacer una prueba de manejo tanto en una calle lenta como en una rápida (en la autopista, por ejemplo). Así podrá tener una mejor idea del desempeño del vehículo (motor, caja de cambios, dirección, frenos, suspensión) a bajas y altas velocidades. Durante la prueba esté atento a cualquier ruido y/o comportamiento inusual."
      },
      tip2 : {
        title: "Apariencia",
        detail: "Existen dos factores que también son un papel muy importante en el precio de un vehículo usado, se trata de la condición estética de la carrocería y de la cabina. Hay que asegurarse de revisar detenidamente el interior y el exterior. Si se encuentran defectos leves o que puedan repararse, esto no debería impedir la comprar del automóvil, recuerde que en carretera el vehículo está expuesto a desgaste. Eso sí, evalúe bien el defecto y negocie con el vendedor."
      },
      tip3 : {
        title: "Fugas",
        detail: "También es importante examinar visualmente el motor. Las partes sucias y oxidadas pueden ser una evidencia sólida de que habrá problemas más adelante. Cualquier fuga de líquidos en un automóvil es una señal de que necesita reparaciones. Por ello, es importante inspeccionar detalladamente el motor desde la parte superior e inferior. Un líquido negro puede indicar una fuga de aceite, un líquido verde puede indicar una fuga de anticongelante y un líquido rosa puede indicar una fuga en la transmisión."
      },
      tip4 : {
        title: "Investigar el precio",
        detail: "Para saber si lo que pide el vendedor es justo, también es necesario averiguar en Internet los precios. Si bien la condición y el kilometraje afectarán el precio, se puede obtener una buena estimación del precio de venta al buscar vehículos similares. Realice una búsqueda de vehículos similares en CualCarroMeCompro.com y compare precios."
      },
      tip5 : {
        title: "Inspección Profesional",
        detail: "Lleve el vehículo a revisar con su taller mecánico de confianza. Al ser profesionales les es más sencillo descubrir problemas."
      },
      tip6 : {
        title: "No tome una decisión apresurada",
        detail: "Para evitar arrepentimientos, es vital nunca tomar rápido la decisión de comprar un vehículo. Hay que tomarse el tiempo para hacer una investigación exhaustiva y negociar para obtener el mejor precio."
      },
      tip7 : {
        title: "Revisar el VIN",
        detail: "Revisar el VIN del vehículo en Internet permite saber si está registrado legalmente o si no es producto de la clonación, un tipo de fraude común en la venta de vehículo usados."
      }
    },
    sellingUsedCardTips: {
      titlePart1: "Consejos cuando",
      titlePart2: "venda",
      titlePart3: "su vehículo",
      tip1: {
        title: "Buenas fotografías",
        detail: "Tome las mejores fotografías que pueda. Un anuncio sin imágenes no llama la atención o genera desconfianza en los posibles compradores. Además, para la mayoría de los compradores las fotografías son relevantes cuando van a tomar su decisión. ¡Entre más imágenes, mejor! Incluya todas las imágenes que pueda, tanto del exterior como del interior del vehículo."
      },
      tip2: {
        title: "Establece un precio razonable",
        detail: "Ni muy alto ni muy bajo. Antes de fijar el precio realice una búsqueda de vehículos similares en CualCarroMeCompro.com y compare con el precio que quiere fijar. Tenga en cuenta que el valor del vehículo disminuye con el paso de los años y por su uso (kilometraje)."
      },
      tip3: {
        title: "Prepárese para hablar con el comprador",
        detail: "Piense en las preguntas que le podrían hacer acerca del vehículo y si tiene respuesta para todas ellas. Conviene que sea honesto y diga lo que sepa del vehículo para ofrecer al interesado confianza y transparencia."
      },
      tip4: {
        title: "La apariencia cuenta",
        detail: "La limpieza del vehículo y sus partes reflejan el cuidado general que se le ha dado. Así que haga una limpieza profunda cuando lo ponga en venta, sumará muchos puntos."
      }
    }
  },
  currency: {
    crc: "CRC",
    usd: "USD"
  },
  dashboard: {
    opportunitiesInPeriodChart: "Anuncios creados en el periodo",
    calendarUpdated: "Calendario actualizado",
    diagram: {
      freeOpportunities: "Anuncios Gratis",
      leftLabel: "Total de Anuncios",
      payedOpportunities: "Anuncios Pagos"
    },
    commons: {
      title: "Estadísticas Generales",
      revenue: "Total de Ingresos",
      opportunities: {
        onlineTitle: "Anuncios Online",
        paidWithPlanTitle: "Pagados con Plan",
        paidIndividuallyPlanTitle: "Pagados Individualmente",
        created: "Creados válidos",
        free: "Gratuitos",
        paid: "Pagos",
        gold: "Nivel oro",
        silver: "Nivel plata",
        bronze: "Nivel bronce",
        invalid: "Inválidas",
        immediateApproval: "Aprobación Inmediata"
      },
      plans: {
        title: "Planes Vendidos",
        total: "Total",
        sponsoredPlans: "Planes Patrocinado"
      },
      renewals: {
        title: "Renovaciones",
        pending: "Pendientes",
        expired: "Expirados"
      },
      reported: {
        title: "Reportados",
        subTitle: "Total"
      },
      users : {
        total: "Total",
        organic: "Orgánicos",
        impersonalized: {
          total: "Impersonalizados",
          confirmed: "Confirmados",
          notConfirmed: "No Confirmados",
        }
      }
    },
    period: {
      title: "Estadísticas del Período",
      revenue: "Ingresos del Período"
    },
    donutChart: "Cuadro de donas",
    justNow: "Justo ahora",
    newOrders: "Ordenes nuevas",
    opportunityId: "Id Anuncio",
    otherService: "Otros servicios",
    readAllNewMessages: "Leer todos los mensajes nuevos",
    recentCreatedOpportunities: "Anuncios recien creados",
    resolve: "Resolver",
    resolved: "Resuelto",
    statisticsOverview: "Resumen estadisticas",
    supportTickets: "Tiquetes abiertos",
    tasksPanel: "Panel de tareas",
    totalCreatedOpportunities: "Total de Anuncios Creados",
    totalFreeOpportunities: "Total de Anuncios Gratis",
    totalPayedOpportunities: "Total de Anuncios Pagos",
    totalRevenueInPeriod: "Ingresos en el Periódo",
    totalRevenue: "Total de Ingresos",
    numberOfPendingOpportunitiesToRenew: "Total de Anuncios pendiente de renovar",
    numberOfPendingOpportunitiesToExpire: "Total de Anuncios expirados",
    purchases: {
      opportunities:"Anuncios",
      plans:"Planes",
      title: "Compras por Usuario",
      fullName: "Usuario",
      goldLevel: "Nivel Oro",
      silverLevel: "Nivel Plata",
      bronzeLevel: "Nivel Bronce",
      freeLevel: "Gratis",
      userData: "Usuario",
      seeList: "Ver listado",
      quantity: "Cant",
      paidAmount: "Monto",
      userOpportunities: "Anuncios",
      totalPaid: "Pago Total",
      purchasedQuantity: "Comprados",
      publishedQuantity: "Publicados",
      invoices: "Facturas"
    }
  },
  domain:{
    baseUrl:"https://cualcarromecompro.com/%23/",
    serverUrl:"https://cualcarromecompro.com/"
  },
  "error-codes": {
    errorCode_0001: "Correo o contraseña incorrecta",
    errorCode_0002: "Su cuenta no ha sido confirmada",
    errorCode_0004: "El link de confirmación no es válido",
    errorCode_0005: "El usuario está bloqueado por intentos fallidos. Vuelva a intentar en 30 minutos o cambie la contraseña."
  },
  "error-messages": {
    atLeastOnePhone: "Por favor ingrese al menos un número de télefono",
    errorOccurred: "Ha ocurrido un error. Por favor intente de nuevo",
    invalidAddress: "Por favor ingrese la dirección exacta",
    invalidBrand: "La marca no es válida. Seleccione una opción",
    invalidCarIdNumber: "El número de placa del vehículo: {value} no es válido. El número de placa debe ser alfabético o numérico.",
    invalidCurrency: "La moneda es inválida. Seleccione una opcion",
    invalidPassengersNumber: "El número de pasajeros no es válido. Seleccione una opción",
    invalidEmail: "El correo electrónico {value} no es válido. Por favor ingrese un correo electrónico válido",
    invalidEmailVerified: "Si su correo electrónico ya está verificado, recibirá un correo electrónico para restablecer la contraseña. Gracias",
    invalidExteriorColor: "El color exterior {value} no es válido. Se permiten solo letras, números, guíones y espacios",
    invalidFuelType: "El tipo de combustible no es válido. Seleccione una opción",
    invalidIdentification: "La identificación {value} no es válida. La identificación debe ser solo alfabética o numérica",
    invalidIdType: "El tipo de identificación no es válido. Seleccione una opcion",
    invalidInteriorColor: "El color interior {value} no es válido. Se permiten solo letras, números, guíones y espacios",
    invalidKnownDamage: 'Máximo {value} caracteres',
    invalidLastName2User: "El segundo apellido {value} no es válido. El apellido debe ser alfabético con 2 mínimo de longitud",
    invalidLastNameUser: "El primer apellido {value} no es válido. El apellido debe ser alfabético con 2 mínimo de longitud",
    invalidMileage: "El kilometraje: {valor} no es válido. El kilometraje debe ser solo caracteres numéricos",
    invalidModel: "El modelo {value} no es válido. Se permiten solo letras, números, guíones y espacios",
    invalidMotorSizeCC: "El cilindraje: {value} no es válido. El cilindraje debe ser solo caracteres alfabéticos con 3 longitudes mínimas",
    invalidName: "El nombre {value} es inválido. El nombre debe tener mínimo dos caracteres.",
    invalidNameExtra: "El nombre {name} no es válido. Nombre con 2 caracteres mínimo",
    invalidNameUser: "El nombre {value} no es válido. El nombre debe ser alfabético con 2 mínimo de longitud",
    invalidMerchantNameUser: "El nombre del comercio {value} no es válido. El nombre debe ser alfabético con 2 mínimo de longitud",
    invalidPassword: "La contraseña debe tener entre 6 y 20 caracteres sin espacios",
    invalidPasswordConfirm: "Las contraseñas no coinciden.",
    invalidPayTaxes: "El pago de marchamo no es válido. Seleccione una opción",
    invalidPhone: "El teléfono debe ser númerico (al menos 8 dígitos)",
    invalidPrice: "El precio: {value} no es válido. El precio debe ser mayor a 0",
    invalidProvince: "La provincia no es válida. Seleccione una opción",
    invalidTransferPrice: "El precio traspaso: {value} no es válido.",
    invalidQuantity:"La cantidad de anuncios debe ser mayor a {minimum}",
    invalidSellerType: "El tipo de vendedor no es válido. Seleccione una opción",
    invalidStatus: "El estado no es válido. Seleccione una opción",
    invalidStatusExtra: "El estatus no es válido. Seleccione una opción",
    invalidStyle: "El estilo no es válido. Seleccione una opción",
    invalidTransmissionType: "El tipo de transmisión no es válido. Seleccione una opción",
    invalidUserRol: "El rol de usuario no es válido. Seleccione una opción",
    invalidUserType: "El tipo de usuario no es válido. Seleccione una opción",
    invalidYear: "El año no es válido. Seleccione una opción",
    PasswordTooShort: "El password está vácio o es muy corto, favor verificar",
    oldAndNewPasswordMustBeDifferent: "La contraseña actual y la nueva deben ser diferentes",
    invalidLanguage: "El idioma no es válido. Seleccione una opción",
    required: "Este campo es requerido",
    invalidNumber: "El valor debe ser un número",
    opportunityReviewAlert: "Por favor revise los campos arriba y corrija los errores para continuar",
    invalidTermsAndConditions : 'Por favor acepte los términos y condiciones'
  },
  extra: {
    extras: "Extras"
  },
  footer: {
    contact: "Contacto",
    contactEmail: "soporte@cualcarromecompro.com",
    contactPhone: "8738-7020",
    emailLabel: "Correo",
    phoneLabel: "Teléfono",
    privacyPolicy: "Política de Privacidad",
    whatsappLabel: "WhatsApp",
    copyright: "CualCarroMeCompro.com Todos los derechos reservados.",
    version : {
      backend : "BE",
      frontend : "FE",
      database :"BD"
    }
  },
  fuelTypes: {
    diesel: "Diesel",
    electric: "Eléctrico",
    gasoline: "Gasolina",
    hybrid: "Híbrido",
    lpg: "Gas"
  },
  fupload: {
    again: "Intente de nuevo. Verifique que esté seleccionando un archivo de imagen con extensión .jpg o .png",
    error: "La carga ha fallado.",
    message: "Arrastre sus archivos aquí,",
    more: "Subir más fotos",
    submessage: "o haga click para seleccionar",
    maxAttach: "(máximo {maxAttachments} archivos)",
    success: "Se subieron {fileCount} archivo(s) exitosamente.",
    uploading: "Subiendo {fileCount} archivos..."
  },
  globalParameters: {
    globalParameters: "Parametros Globales",
    TOTAL_ITEMS_TO_DISPLAY_ON_LISTS: "Cantidad de items a mostrar en listas",
    USER_MAX_QUANTITY_FAVOURITES: "Cantidad máxima de anuncios en Mis Favoritos permitidas por usuario",
    USER_SESSION_DURATION: "Duracion de session de usuario (en minutos)",
    PAYED_OPPORTUNITY_EXPIRATION_DAYS: "Cantidad de días para que un anuncio pago expire",
    FREE_OPPORTUNITY_EXPIRATION_DAYS: "Cantidad de días para que un anuncio gratis expire",
    NUMBER_OF_DAYS_BEFORE_EXPIRATION_TO_NOTIFY: "Número de días antes que el anuncio expire en que se debe enviar la notificación al usuario",
    NUMBER_OF_GOLD_OPPORTUNITIES_IN_CAROUSEL: "Cantidad de anuncions nivel Gold a desplegar en el carrusel de la página principal",
    SINGLE_GOLD_LEVEL_OPPORTUNITY_PRICE: "Precio individual de los anuncios nivel Oro",
    SINGLE_SILVER_LEVEL_OPPORTUNITY_PRICE: "Precio individual de los anuncios nivel Plata",
    SINGLE_BRONZE_LEVEL_OPPORTUNITY_PRICE: "Precio individual de los anuncios nivel Bronce",
    PLAN_GOLD_LEVEL_OPPORTUNITY_PRICE: "Precio individual de los anuncios nivel Oro en un plan",
    PLAN_SILVER_LEVEL_OPPORTUNITY_PRICE: "Precio individual de los anuncios nivel Plata en un plan",
    PLAN_BRONZE_LEVEL_OPPORTUNITY_PRICE: "Precio individual de los anuncios nivel Bronce en un plan",
    IMMEDIATE_APPROVAL_PRICE:"Precio por aprobación inmediata",
    MIN_OPPORTUNITIES_TO_BUY_IN_PLAN: "Cantidad mínima de anuncios a comprar en un plan",
    successUpdate: "Los Parametros Globales se han actualizado satisfactoriamente"
  },
  identificationTypes: {
    juridicId: "Cédula jurídica",
    NITE: "NITE",
    foreignId: "Cédula del extranjero",
    none: "Ninguna",
    nationalId: "Número de Cédula",
    residenceId: "Número de residencia",
    title: " Tipo de Identificacion"
  },
  payment : {
    methods :{
      creditOrDebitCard: "Tarjeta de Débito/Crédito",
      sinpeMovil: "SINPE Móvil"
    },
    result : {
      paymentCompleted : "Pago Completado",
      clarification : "Muchas gracias por su compra. ¡Su anuncio ha sido publicado!",
      backButton : "Volver a Inicio"
    },
    loadingPaymentForm: "Cargando formulario de Pago"
  },
  home:{
    mainTitle:"Encuentre su próximo vehículo, entre cientos de opciones disponibles",
    mainButton:"Ver Vehículos",
    goldBox: {
      title: "Destaque su Anuncio a nivel ORO",
      text: "Su anuncio se mostrará en los primeros resultados de búsqueda dentro de CualCarroMeCompro.com (primero que los anuncios nivel Plata y gratuitos). Además se publicará en nuestra página de Facebook.",
      button: "Publicar"
    },
    silverBox: {
      title: "Destaque su Anuncio a nivel PLATA",
      text: "En los resultados de búsqueda dentro de CualCarroMeCompro.com su anuncio se mostrará primero que los anuncios gratuitos. NO se publicará en nuestra página de Facebook.",
      button: "Publicar"
    }
  },
  invoice: {
    title: "Información de Pago",
    instructions: "El pago del anuncio se puede realizar via SINPE Móvil, para ello:",
    instruction1: "Consulte el monto total a cancelar que se muestra arriba.",
    instruction2: "Ingrese su SINPE Móvil y realice el pago a nuestro número:",
    instruction2_1: "a nombre de",
    instruction3: "Ingrese el número de referencia en el campo de abajo.",
    instruction4: "Si tiene cualquier consulta no dude en contactarnos.",
    refnum: "Número de referencia SINPE",
    electronic: "Requiere factura electrónica",
    invoiceInstructions: "Por favor complete el siguiente formulario con los datos para generar su factura:",
    idType: "Tipo de identificación",
    identification: "Número de identificación",
    fullname: "Nombre completo",
    address: "Dirección",
    email: "Correo electrónico",
    phone: "Número de teléfono",
    amount: "Monto",
    createdDate: "Fecha",
    status:"Estado"
  },
  mainMenu: {
    admin: "Administrador",
    advertisement: "Publicar Anuncio",
    dashboard: "Dashboard",
    home:"Inicio",
    login: "Ingrese nuevamente, su session está expirada o inválida.",
    logout: "Salir",
    myAdvertisements: "Mis anuncios",
    opportunitiesAdministration: "Administración de anuncios",
    signInLogin: "Registrarse/Ingresar",
    usedVehicles: "Ver Vehículos",
    bookmarks: "Mis Favoritos",
    plans: "Destacados Disponibles",
    resizeThumbnails: "Resize Thumbnails"
  },
  opportunity: {
    addToFavorites: "Agregar a Mis Favoritos",
    bluetooth: "Bluetooth",
    brand: "Marca",
    camion: "Camión",
    canton: "Cantón",
    carIdNumber: "Placa completa (no se mostrará en el anuncio)",
    colors: {
      beige: "beige",
      black: "negro",
      blue: "azul",
      brown: "café",
      green: "verde",
      grey: "gris",
      red: "rojo",
      silver: "plateado",
      white: "blanco"
    },
    contactInfo: "Información de contacto",
    contactInformation: "Información de contacto",
    contactName: "Nombre del Contacto",
    contactSeller: "Contactar Vendedor",
    cover: "Carátula",
    descriptionCar: "Descripción del vehículo",
    district: "Distrito",
    exteriorColor: "Color exterior",
    extrasCategory: {
      confort: "Comodidad",
      segurity: "Seguridad",
      audioVideo: "Audio - Video",
      exterior: "Exterior"
    },
    extras: {
      ABS_BRAKES: "Frenos ABS",
      ADJUSTABLE_STEERING_WHEEL: "Volante ajustable",
      AIRBAGS: "Bolsa(s) de aire",
      AIR_CONDITIONING: "Aire acondicionado",
      ALARM: "Alarma",
      CENTRAL_LOCKING: "Bloqueo central",
      CRUISE_CONTROL: "Control crucero",
      DASH_SCREEN:"Pantalla en dash",
      DIRECTIONAL_MIRROR:"Direccionales en espejos",
      DUAL_GEARBOX: "Caja de cambios dual",
      DVD_SCREEN: "Pantalla para DVD",
      ELECTRIC_GLASSES: "Vidrios eléctricos",
      ELECTRIC_MIRRORS: "Espejos eléctricos",
      ELECTRONIC_STABILITY: "Control electrónico de estabilidad",
      FRONT_SENSORS: "Sensores frontales",
      HALOGEN: "Halógenos",
      HYDRAULIC_STEERING: "Dirección hidráulica",
      LEATHER_UPHOLSTERY: "Tapicería de cuero",
      LEATHER_UPHOLSTERY_CLOTH:"Tapicería de cuero y tela",
      LUGGAGE_COVER:"Cubremaletas",
      LUXURY_RINGS: "Aros de lujo",
      MEMORY_SEAT: "Asiento(s) con memoria",
      MULTIFUNCTION_STEERING: "Control de radio en el volante",
      POLARIZED_GLASSES: "Vidrios polarizados",
      POWER_SEATS: "Asientos eléctricos",
      RACKS:"Racks",
      RADIO_WITH_CASSETTE: "Radio con cassette",
      RADIO_WITH_CD: "Radio con CD",
      RADIO_WITH_USB: "Radio con USB/AUX",
      RAIN_SENSOR: "Sensor de lluvia",
      REAR_DEFROSTER: "Desempañador trasero",
      REVERSE_CAMERA: "Cámara de retroceso",
      REVERSE_SENSORS: "Sensores de retroceso",
      ROOF_BASKET:"Canasta en techo",
      ROOF_RAILS:"Rieles en techo",
      RUBBER_MAT:"Alfombras de hule",
      SELF_RETRACTING_MIRRORS: "Espejos retráctiles",
      SMART_KEY: "Llave inteligente/Botón de arranque",
      STEERING_WHEEL_CONTROL: "Volante multifuncional",
      STEERING_WHEEL_GEARSHIFT:"Cambios en volante",
      SUNROOF: "Sunroof/techo panorámico",
      TIRE_PRESSURE_MONITOR: "Monitor de presión de llantas",
      TRAVEL_COMPUTER: "Computadora de viaje",
      TURBO: "Turbo",
      XENON_LIGHTS: "Luces de xenón/bixenón"
    },
    fuelType: "Tipo de combustible",
    id: "Id",
    interiorColor: "Color interior",
    knownDamage: "Detalles",
    level: "Nivel",
    messages: {
      completeForm: "Por favor, ingrese valores correctos en cada campo requerido"
    },
    mileage: "Kilometraje",
    mileageMax: "Kilometraje Máximo",
    mileageUnit: {
      title: "Unidad de Kilometraje",
      kilometers: "Kilómetros",
      miles: "Millas",
      notAvailable:"no disponible",
      KM: "Km",
      MI: "Mi"
    },
    model: "Modelo",
    modified: "Modificados",
    moreThan7: "más de 7",
    motorSizeCC: "Tamaño del motor (cc)",
    motorSizeCCRange: "Tamaño del motor",
    new: "Nuevo",
    passengersNumber: "Número de pasajeros",
    outstanding: "Anuncio Destacado",
    payTaxes: "Marchamo al día",
    price: "Precio",
    province: "Provincia",
    reportAd: "Reportar este anuncio",
    reportAdTitle: "Reportar anuncio",
    reportAdDescription: "Por favor ingrese una breve descripción del motivo por el cual desea reportar este anuncio.",
    reportAdLabel: "Motivo del reporte",
    reportAdReport: "Reportar",
    reportAdThanks: "Su reporte ha sido registrado, muchas gracias.",
    sellerType: "Tipo de vendedor",
    sendWhatsApp: "Envíar WhatsApp",
    sendWhatsAppMessage: "Estoy interesado en el anuncio que vi en CualCarroMeCompro.com del ",
    stepOf: "Paso {value} de 3",
    step4: {
      emptyExtras: "Los extras apareceran aquí...",
      messageCheckboxText: "Al seleccionar esta opción se permite seleccionar un mensaje adicional de entre varias alternativas de texto recomendadas, o escribir el mensaje personalizado.",
      messageCheckboxTitle: "Mensaje Personalizado:",
      mniatureLabel: "(vista previa)",
      newCheckboxText: 'Al marcar esta opción el anuncio sera etiquetado como "nuevo" durante los primeros 4 días de su publicación. Los anuncios etiquetados como "nuevo" tienen prioridad en la sección de anuncios recientes (mayor que los anuncios que unicamente son destacados)',
      newCheckboxTitle: "Etiqueta de anuncio nuevo:",
      optionChange: "Se recibe",
      optionCustom: "Mensaje personalizado",
      optionFullExtras: "Full extras",
      optionNegotiable: "Negociable",
      optionPerfect: "Perfecto estado",
      outstandingCheckboxText: "Con esta opción el anuncio tiene prioridad sobre los anuncios regulares en los resultados de busqueda de los usuarios. Los anuncios destacados también tienen prioridad en otras secciones del sitio web, como la sección de anuncios recientes. Adicionalmente existen otras opciones especiales exclusivamente disponibles para anuncios destacados.",
      outstandingCheckboxTitle: "Resaltar anuncio:",
      payedByPlan:"Se descontará del plan",
      price: "Precio:",
      resaltarAnuncio: "Confirmación",
      termCheckLink: "Términos y Condiciónes de Uso",
      termCheckText: "He leido, comprendo y acepto los",
      total: "Total:"
    },
    uploadPhotos:"Subir Fotos",
    landscapePhotoAdvice: "Para un mejor resultado, recomendamos tomar las fotos con su teléfono o cámara de manera horizontal.",
    style: "Estilo",
    sellerOpportunities: "Ver más anuncios de este vendedor",
    transferPrice: "Costo traspaso (apróximado)",
    transmission: "Transmisión",
    vehicleExtras: "Extras del vehículo",
    views: "Vistas",
    viewPhones: "Ver Teléfonos",
    yearModel: "Año del modelo",
    freeRegistrationInProcess: "Creando su anuncio",
    freeRegistrationCompleted: {
      mainMessage: "Su anuncio se registró satisfactoriamente",
      clarification : "Su anuncio se encontrará disponible muy pronto!",
      backButton : "Volver a Inicio"
    }
  },
  opportunityStatus: {
    APPROVED: "Aprobado",
    MODIFIED: "Modificado",
    PENDING_APPROVAL: "Aprobación pendiente",
    REJECTED: "Rechazado",
    SOLD: "Vendido",
    EXPIRED: "Expirado",
    RENEWED: "Renovada",
    PUBLIC_ONLINE: "Públicas en Línea",
  },
  opportunityStatusHistory: {
    id: "Id",
    status: "Estatus",
    fullName: "Nombre Completo",
    email: "Corre Electrónico",
    statusDate: "Fecha",
    history: "Historico",
    title: "Historico para Anuncio"
  },
  messagesTitle: {
    error: "Error",
    info: "Información",
    success: "Exitoso",
    warning: "Advertencia"
  },
  pagination: {
    "aria-label": "Paginación",
    current: "(actual)",
    first: "Primera página",
    goto: "Ir a {page}",
    last: "Última página",
    next: "Siguiente",
    previous: "Anterior"
  },
  passwordRecovery: {
    title: "Olvidó su contraseña?",
    mainText: "Ingrese el correo electrónico que usa para acceder a su cuenta",
    submit: "Enviar",
    resetedTitle: "Tu contraseña se a reestablecido satisfactoriamente",
    resetedText: "Por favor ve a la pantalla de inicio de sesión e ingresa usando tu nueva contraseña",
    resultTitle: "Revisa tu Correo Electrónico ",
    resultText: "Si tenemos una cuenta registrada con el correo electrónico provisto, enviaremos un mensaje con un enlace para recuperar su contraseña",
    continue: "Continuar"
  },
  personTypes: {
    legal: "Persona jurídica",
    natural: "Persona física"
  },
  phoneDescription: {
    home: "Casa",
    landline: "Fijo",
    merchant: "Linea comercial",
    mobile: "Celular",
    whatsapp: "Whatsapp"
  },
  plan: {
    availableOpportunities: 'Disponibles: {available} ',
    title: 'Nivel del anuncio',
    label: 'Nivel del plan',
    expirationDate : 'Expira',
    preamble1: 'Tu venta es importante y tu anuncio también, demuéstralo resaltando tu anuncio para que aparezca antes que el de otros anunciantes.',
    preamble2: 'Resaltar tu anuncio aumenta las vistas y genera mayor confianza a los compradores potenciales.',
    gold: {
      radio: 'Nivel ORO',
      preamble: 'Su vehículo se vende más rápido al aparecer en los primeros lugares de las búsquedas. El anuncio es mucho más visto.',
      p1: 'Posicionado antes que anuncios plata y gratuitos.',
      p2: 'Se publica en nuestra página de Facebook.',
      p3: 'Fotografía tamaño grande.',
      p4: 'Renovación automática.',
      p5: 'Mayor cantidad de fotos (hasta 20 fotografías) que anuncios plata y gratuitos.',
      p6: 'Duración del anuncio: 1 mes. Con renovación automática.',
      p7: 'Aprobación inmediata.',
      msg: 'Mensaje personalizado:',
      msgHelp: 'Máximo {chars} caracteres',
      name: 'Plan nivel oro'
    },
    silver: {
      radio: 'Nivel Plata',
      p1: 'Posicionado antes que anuncios gratuitos.',
      p2: 'Fotografía tamaño normal.',
      p3: 'Renovación automática.',
      p4: 'Mayor cantidad de fotos (hasta 12 fotografías) que anuncios gratuitos.',
      p5: 'Duración del anuncio: 1 mes. Con renovación automática.',
      p6: 'Aprobación inmediata.',
      name: 'Plan nivel plata'
    },
    bronze: {
      radio: 'Resaltar nivel Bronce',
      p1: 'Posicionado antes que anuncios gratuitos.',
      p2: 'Fotografía tamaño normal.',
      p3: 'Renovación automática.',
      p4: 'Mayor cantidad de fotos (hasta 8 fotografías) que anuncios gratuitos.',
      p5: 'Duración del anuncio: 1 mes.',
      p6: 'Aprobación inmediata.',
      name: 'Plan nivel bronce'
    },
    free: {
      radio: 'Nivel Gratis ',
      p1: 'Fotografía tamaño normal.',
      p2: 'Máximo 4 fotografías.',
      p3: 'Duración del anuncio: 2 semanas. Sin renovación automática',
      payToApprove: 'Aprobación inmediata (₡{price} colones)'
    },
    price: 'Precio: ₡{price}',
    total: 'Total: ₡{price} colones',
    buy: {
      pageTitle: "Adquiera o Recargue su plan de anuncios destacados",
      sectionDescription1: "Destaque sus anuncios con hasta un 50% de descuento al comprar un plan!",
      sectionDescription2: "Los planes están especialmente diseñados para negocios de venta de vehículos tanto nuevos como usados",
      plansSectionTitle: "Seleccione plan que desea comprar o recargar",
      features: "Características",
      itemsInPlanTitle: "Cantidad de anuncios a comprar",
      itemsInPlan: "Seleccione la cantidad de anuncios que desea comprar, recuerde que los anuncios tienen validez de un mes antes de su vencimiento.",
      menuLabel: "Comprar Destacado",
      miniumItemsInPlan: "mínimo {minimum} anuncios",
      price: 'Precio por anuncio: ₡{price}',
      process: "Pagar Anuncios",
      gold: {
        radio: 'Plan nivel Oro',
        p7: "Mensajes personalizados"
      },
      silver: {
        radio: 'Plan nivel Plata'
      },
      bronze: {
        radio: 'Plan nivel Bronce'
      }
    },
    status: {
      availableTitle: "Anuncios disponibles por plan",
      goldLabel: "Nivel oro",
      silverLevel: "Nivel plata",
      bronzeLevel: "Nivel bronce",
      expirationDate: "Fecha de expiración",
      dealerInfo: "¡Contamos con planes especiales para agencias y ventas de vehículos!",
      dealerInfoLink: "Ver Planes",
      replenishInfo: "Recuerda que puedes recargar tu plan accediendo a la",
      replenishInfoLink: "página de administración de planes"
    },
    remarkable: 'Elija si desea destacar su Anuncio',
    remarkableInstructions: 'Marque el nivel al que desea destacar su Anuncio; indique la cantidad a comprar y listo, así de fácil',
    remarkablePlanSuggestion: 'Ahorra hasta un 80% al comprar sus anuncios destacados'
  },
  promotions: {
    main: "Publica tu anuncio y gana ₡100.000",
    termsAndConditions : 'Términos y condiciones'
  },
  impersonalRegistration: {
    title: "Registro Impersonal de Usuario",
    giftedOpportunities: "Cantidad de anuncios promocionales",
    promotionalPlan: "Datos del plan promocional",
    success: {
      title: "El Registro Impersonal se ha completado satisfactoriamente",
      text:
          "Un correo electrónico se ha enviado a la dirección suministrada. Para activar la cuenta el usuario debe seguir las instrucciones ahí indicadas."
    }
  },
  registration: {
    acountConfirmed: "Cuenta Confirmada",
    agreementNotChecked: "Para proceder, debe marcar este campo.",
    alreadyRegistered: "Ya registrado",
    cancelChangePassword: "Cancelar cambiar contraseña",
    changePassTitle: "Cambio de Contraseña",
    changePassword: "Cambiar contraseña",
    confirmNewPasword: "Confirme contraseña nueva",
    createAccount: "Crear una cuenta",
    currentPassword: "Contraseña",
    forgotPassword: "Olvidé mi contraseña",
    footerMessage: "Un servicio de cualcarromecompro.com",
    goToLink: "Por favor dar click en el siguiente link para ingresar",
    informationCheck:
        "Hago constar que la información proporcionada es verdadera y correcta.",
    invalidLink: "El enlace es invalido o es un enlace ya usado",
    login: {
      logining: "Iniciando sesión"
    },
    newPassword: "Nueva Contraseña",
    newPasswordConf: "Confirmar nueva contraseña",
    notRegistered: "Sin registrar",
    notRequestMaked: "Si usted no realizo esta solicitud por favor contactenos",
    passwordConfirm: "Confirme la contraseña",
    passwordReset: "Cambiar contraseña",
    passwordResetText: "Por favor digite la nueva contraseña y su confirmación",
    registerAccount: "Registrarse",
    registingAccount: "Registrando cuenta",
    rememberPassword: "Recuerda su contraseña",
    resetPassword: "Cambiar contraseña",
    submitNewPassword: "Enviar nueva contraseña",
    title: "Crea tu cuenta",
    phonesDisplayedWithOpportunities: "Los teléfonos acá ingresados se mostrarán en los anuncios que publique."
  },
  search: {
    filters: "Filtros",
    priceRange: "Rango de Precio",
    simpleSearchText: "Escriba Marca, Modelo, Año",
    mainLegend: "Encuentra el vehículo que buscas",
    maximum:"Max",
    minimum:"Min",
    advancedSearch:"Búsqueda Avanzada",
    backToResults:"Volver a Resultados",
    results: "Resultados de la Búsqueda"
  },
  sellerTypes: {
    carsSellerMerchant: "Comercio",
    individual: "Individuo"
  },
  signin: {
    tittle: "Inicio de Sesión",
    signin: "Iniciar Sesión"
  },
  "success-messages": {
    deletedSuccessExtra: "Extra eliminado exitosamente.",
    emailVerified:
        "Si su correo electrónico ya está verificado, recibirá un correo electrónico para restablecer la contraseña. Gracias",
    registerOpportunity: "Anuncio exitosamente registrado",
    updatePassword: "Su contraseña ha sido actualizada satisfactoriamente",
    updateUser: "Has actualizado con éxito a tu usuario",
    updateUserByAdmin: "Los datos del usuario se han actualizado con éxito",
    updatedOpportunity: "Has actualizado tu anuncio con éxito",
    boughtPlan: "Su compra se ha procesado con éxito",
    canceledPayment: "El intento de pago fue cancelado"
  },
  tc: {
    title: 'Términos de Uso y Condiciones de Servicio',
    subtitle: 'Última actualización: 1 de julio de 2024',
    c1: {
      h: '1. Aceptación de Términos de Uso',
      p1: 'Cada vez que usted utiliza o accede a esta Página Web, está aceptando ser limitado por lo acá descrito y cualquier cambio futuro que se haga con o sin previo aviso. Por favor, lea estos Términos de Uso y Condiciones de Servicio cuidadosamente antes de acceder o utilizar nuestro sitio web. Si no está de acuerdo con todos los términos y condiciones acá planteados, entonces no debería acceder a la página web o usar el servicio.'
    },
    c2: {
      h: '2. Información General',
      p1: 'Luzmax Soluciones S.A. es una empresa constituida bajo las Leyes de la República de Costa Rica, con Cédula Jurídica: 3-101-467489 y domicilio en Curridabat, San José, barrio El Prado, la cual creó y es dueña del sitio web CualCarroMeCompro.com. En adelante el término “CualCarroMeCompro.com” o “CualCarroMeCompro” se refieren a Luzmax Soluciones S.A'
    },
    c3: {
      h: '3. Servicio',
      p1: 'CualCarroMeCompro.com tiene como objetivo, servir como una herramienta basada en la nube que permite publicar anuncios de automóviles. Usted acepta que los dueños de esta Página Web se reserven exclusivamente el derecho y puedan, en cualquier momento con o sin aviso y sin ninguna responsabilidad hacia usted, a modificar o descontinuar el uso de esta Página Web y sus servicios o suprimir los datos que usted proporcione, temporal o permanentemente.'
    },
    c4: {
      h: '4. Condiciones de servicio',
      p1: 'Todos los anuncios serán revisados y deberán ser aprobados por CualCarroMeCompro.com antes de hacerse públicos y aparecer en los resultados de búsqueda de la página.',
      p2: 'Usted acepta que CualCarroMeCompro.com se reserva el derecho y puede en cualquier momento con o sin aviso y sin ninguna responsabilidad hacia Usted, a modificar o descontinuar el uso de esta Página Web y sus servicios.',
      p3: 'CualCarroMeCompro.com no tendrá ninguna responsabilidad hacia Usted por la puntualidad, cancelación, falta de espacio, o la publicación incorrecta de información.',
      p4: 'Usted acuerda que toda la información de cualquier clase, proporcionada para la publicación de un anuncio, serán responsabilidad única del de usted como Anunciante.',
      p5: 'CualCarroMeCompro.com no será responsable ante Usted, de ninguna manera, del contenido que sea publicado en los anuncios, ni de cualquier error u omisión que estos conlleven.',
      p6: 'CualCarroMeCompro.com se reserva el derecho de limitar la cantidad de anuncios gratuitos a cualquier Anunciante.',
      p7: 'Usted acuerda que CualCarroMeCompro.com puede retirar los textos o imágenes que se juzguen desagradables u ofensivos.',
      p8: 'Solamente se permite anunciar un automóvil por anuncio.',
      p9: 'Solamente se permite anunciar carros. No se permite anunciar vehículos como motocicletas, cuadriciclos, etc.',
      p10: 'No se permite publicar anuncios con información inexacta o falsa. El anunciante se compromete a brindar información real.',
      p11: 'El tiempo de permanencia de un anuncio en CualCarroMeCompro.com dependerá del tipo del nivel del anuncio y será definido por CualCarroMeCompro.com. Actualmente para los anuncios pagos su permanencia es de un <u>mes y medio</u> y para los gratis de <u>dos semanas</u>.',
      p12: 'Si el vehículo anunciado es vendido el anunciante deberá eliminarlo CualCarroMeCompro.com.',
      p13: 'Todos los anuncios expiran. Aquellos con nivel diferente a “Gratuito” se renovarán automáticamente <b>una sola vez</b>. Es responsabilidad del Anunciante estar pendiente de la fecha de vencimiento del anuncio.',
      p14: 'El precio del vehículo anunciado debe ser del precio TOTAL que el comprador pagará por el vehículo.',
      p15: 'Si ingresa el valor del vehículo antes de pagar impuestos debe indicarlo en el formulario de creación de anuncios marcando no marcando la opción de “¿ya pagó impuestos?”.',
      p16: 'Solo se permiten fotografías del mismo vehículo anunciado. Todas deben mostrar alguna parte del vehículo.',
      p17: 'Las fotografías no deben tener otros anuncios insertados, o sobrepuesto, teléfonos, redes sociales, o publicidad del anunciante.',
      p18: 'CualCarroMeCompro.com podrá eliminar fotografías de anuncios que le parezcan inadecuadas, cuya resolución sea mala o cualquier otra condición que a su consideración sea motivo suficiente para eliminarla.',
      p19: 'No se permite publicar anuncios gratuitos duplicados, es decir, del mismo vehículo. Si eso sucede CualCarroMeCompro.com borrará cualquiera de los duplicados.',
      p20: 'Solamente se permite publicar anuncios para la venta de vehículos. No se permite publicar anuncios para la compra de un vehículo.',
      p21: 'CualCarroMeCompro.com se reserva el derecho de eliminar CUALQUIER anuncio que considere va en contra de sus políticas o pueda ser perjudicial para la imagen del sitio, aunque éste no incumpla con ninguna de las otras condiciones aquí descritas.',
      p22: 'Cualquier usuario que no cumpla con los Términos y Condiciones aquí descritos podrá ser bloqueado de publicar anuncios en el futuro.',
      p23: 'Al registrarse en CualCarroMeCompro.com usted acepta recibir correos informativos y/o publicitarios provenientes de CualCarroMeCompro.com y además poder ser contactado vía telefónica. El usuario si así lo desea puede solicitar desinscribirse en cualquier momento que lo desee.',
      p24: 'Usted puede solicitar la cancelación o actualizar la información de su cuenta de usuario en cualquier momento enviándonos un correo electrónico a la dirección de contacto mostrada más abajo.'
    },
    c5: {
      h: '5. Política De Privacidad',
      p1: 'CualCarroMeCompro se preocupa por la privacidad de los datos que Usted proporciona, por lo que se compromete a no compartir esta información con personas externas a CualCarroMeCompro.com.'
    },
    c6: {
      h: '6. Consentimiento a uso de la información',
      p1: 'El crear una cuenta y la publicar anuncios en CualCarroMeCompro.com implica que la información del anunciante estará disponible públicamente en el sitio web para que posibles compradores puedan contactarse con el anunciante.'
    },
    c7: {
      h: '7. Registro y contraseña',
      p1: 'Al registrarse en CualCarroMeCompro.com creará una nueva cuenta ligada a una dirección de correo electrónico y creará una contraseña. Cada vez que ingresé deberá brindar ambos.',
      p2: 'Es responsabilidad del usuario que registró la cuenta, administrar y proteger correctamente su contraseña. CualCarroMeCompro.com no se hace responsable por las consecuencias negativas que puedesufrir a causa de un mal manejo, voluntario o involuntario, de su contraseña.'
    },
    c8: {
      h: '8. Reservación de los derechos',
      p1: 'CualCarroMeCompro.com es el único y legítimo autor de todos los derechos de propiedad intelectual relacionados el sitio web y toda la una plataforma de software como desarrollada para ofrecer el servicio.'
    },
    c9: {
      h: '9. Información Miscelánea',
      p1: 'La nulidad de algún punto de este documento no invalida las demás'
    },
    c10: {
      h: '10. Evite Estafas y Fraudes – Consideraciones',
      p1: 'Al llamar al anunciante, usted se está comunicando directamente con quien puso el anuncio, CualCarroMeCompro.com no tiene responsabilidad ni del contenido del anuncio, ni de la respuesta del anunciante.',
      p2: 'Si es posible, conozca al vendedor en persona.',
      p3: 'NUNCA transfiera dinero a desconocidos.'
    },
    c11: {
      h: '11. Planes',
      p1: 'Los Planes contienen anuncios prepagados de cierto nivel que el usuario compra con antelación a la publicación de sus anuncios. CualCarroMeCompro.com ofrece un descuento en el costo de cada anuncio comprado dentro de un plan (generalmente un 50%) para quienes compren un plan con anuncios prepagados.',
      p2: 'Los anuncios prepagados dentro de un Plan podrán ser usados luego por el Anunciante, cuando este esté creando un nuevo anuncio en CualCarroMeCompro.com.',
      p3: 'Los anuncios dentro del Plan deben ser utilizados en un tiempo prudente ya que la estos tienen una fecha de expiración de <u>un mes</u>. En caso de trascurrido este tiempo el plan se bloqueará y los anuncios dentro de este no podrán usarse.',
      p4: 'En caso que el usuario compre un nuevo plan (con anuncios del mismo nivel de un Plan bloqueado), desbloqueará los anuncios sin usar en el viejo plan bloqueado.'
    },
    c12: {
      h: '12. Política De Cancelaciones y Devoluciones',
      p1: 'Los anuncios pagos publicados en CualCarroMeCompro.com pueden ser cancelados con máximo 1 día después de ser pagados. En estos casos CualCarroMeCompro.com hará la devolución del monto pagado. <b>Las devoluciones de dinero se realizan a la misma tarjeta con la cual se ejecutó el pago del anuncio</b>. Para aplicar la cancelación y coordinar la devolución del dinero, se debe contactar a CualCarroMeCompro.com (ver sección “Información de Contacto”).',
      p2: 'Una vez se recibe la petición para cancelar un anuncio, esta será revisada por el equipo de CualCarroMeCompro.com, el cual verificará que la petición haya sido realizada por el usuario que publicó el anunció. Luego de esto se procederá a realizar el reembolso y la eliminación del anuncio en la página web.'
    },
    c13: {
      h: '13. Información de Contacto',
      p1: 'Preguntas acerca de los Términos de Servicio o cualquier otra inquietud, deben ser enviadas a nuestro correo electrónico para soporte al cliente: soporte@cualcarromecompro.com, escribirnos por la aplicación WhatsApp al número de teléfono +506 8738-7020 o llamarnos al mismo número.'
    }
  },
  transmissionTypes: {
    automatic: "Automático",
    manual: "Manual",
    tiptronic: "Shiftronic"
  },
  user: {
    contactName: "Nombre del Contacto",
    contactLastName: "Apellido del Contacto",
    contactLastName2: "Segundo Apellido del Contacto",
    createNewUser: "Crear nuevo Usuario",
    dateCreated: "Fecha de Creación",
    idNumber: "Número de identificación",
    idType: "Tipo de identificación",
    lastName: "Primer apellido",
    language: "Idioma",
    maintenance: {
      ownOpportunities: "Ver Anuncios",
      userOpportunities: "Anuncios del usuario"
    },
    merchant: "Comercio",
    merchantName: "Nombre del Comercio",
    name: "Nombre",
    passRequirement: "Escriba su contraseña por seguridad",
    password: "Contraseña",
    person: "Persona",
    personalInformation: "Información personal",
    picture: "Foto",
    primaryPhone: "Teléfono",
    profile: "Perfil",
    registration: {
      success: {
        title: "Registro completado satisfactoriamente",
        text:
            "Por favor revise su correo electrónico, un mensaje ha sido enviado a la dirección suministrada. Para activar su cuenta siga las instrucciones ahí indicadas."
      }
    },
    roles: {
      ADMINISTRATOR: "Administrador",
      ADVERTISER: "Anunciante",
      TESTUSER: "Usuario de Prueba",
      IMPERSONALIZATOR: "Impersonalizador"
    },
    sortBy: "Filtrar por",
    secondLastName: "Segundo Apellido",
    userId: "Id Usuario",
    userProfile: "Perfil de Usuario",
    userRegister: "Usuarios registrados",
    userRol: "Rol del usuario",
    usersMaintenance: "Mantenimiento de usuarios",
    userType: "Tipo de usuario"
  },
  password: {
    errorstitle: "Errors:",
    errors: {
      minlen: "La contraseña debe tener al menos 8 caracteres",
      maxlen: "La contraseña no debe tener más de 64 caracteres",
      surroundingspaces:
          "La contraseña no puede comenzar ni terminar con espacios",
      invalidchar: "La contraseña contiene uno o más caracteres no soportados"
    },
    hintstitle: "Sugerencias:",
    hints: {
      validchars:
          "Los caracteres soportados son letras (mayúsculas y minúsculas sin acentos), números, espacios y los símbolos especiales: @#$%&!?.,:;()[]{}<>'\"`~^+-*=\\|/_",
      repetition:
          "Evite repetir secuecias de caracteres como '111' o 'aaa' ya que son faciles de adivinar",
      sequences:
          "Evite secuecias de caracteres como 'abc' o '321' ya que son faciles de adivinar",
      keystrokes:
          "Evite secuecias de teclas consecutivas como 'qwerty' ya que son faciles de adivinar",
      common:
          "Evite contraseñas comunes como 'passw0rd' ya que son faciles de adivinar",
      context:
          "Evite usar informacion predecible como su nombre o teléfono como parte de la contraseña",
      strength:
          "Puede mejorar el nivel de seguridad añadiendo más caracteres o combinando numeros, minúsculas y mayúsculas"
    },
    strength: {
      excellent: "Felicitaciones, la contraseña es excelente!",
      good: "La contraseña es segura",
      fair: "La contraseña es buena, pero se puede mejorar",
      weak:
          "Advertencia: la contraseña suministrada puede ser adivinada con facilidad",
      default: ""
    }
  }
};