<template>
  <div class="container">
    <div v-if="isRequestInProgress">
      <loading
        v-show="isRequestInProgress"
        :message="'registration.registingAccount'"
      ></loading>
    </div>
    <div v-else>
      <section class="container white-box mt-3">
        <h3>{{ $t("registration.title") }}</h3>
        <user-data
          :attempts="registerAttempts"
          @user-data="user = $event"
        ></user-data>
        <correct-data-disclaimer
          :attempts="registerAttempts"
          @disclaimer-agreed="agreed = $event"
        >
        </correct-data-disclaimer>
        <div v-if="!isLocalhost" class="mt-4">
          <vue-hcaptcha
            sitekey="3a8dd8e7-9120-4786-af4f-1ddb405ff246"
            @verify="onVerify"
            @expired="onExpire"
            @error="onError"
          />
        </div>
        <div v-if="errorMessage" class="row mt-3 gy-3">
          <p class="text-danger">
            {{ $t(errorMessage) }}
          </p>
        </div>
      </section>
      <section class="button-box">
        <button type="button" class="btn btn-primary" @click="doSave()">
          {{ $t("registration.registerAccount") }}
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import { ApiService } from "@/rest.js";

import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { User, Credentials } from "@/entities.js";
import UserData from "@/components/user/UserData.vue";
import CorrectDataDisclaimer from "@/components/user/CorrectDataDisclaimer.vue";
import Loading from "@/components/utilities/Loading.vue";

export default {
  name: "RegisterView",
  components: {
    VueHcaptcha,
    Loading,
    UserData,
    CorrectDataDisclaimer
  },
  data() {
    return {
      user: new User(),
      updatingUser: new User(),
      credentials: new Credentials(),
      registerAttempts: 0,
      errorMessage: "",
      isRequestInProgress: false,
      agreed: false,
      // CAPTCHA
      isLocalhost:
        location.hostname === "localhost" || location.hostname === "127.0.0.1",
      verified: false,
      token: null,
      ekey: null,
      error: null
    };
  },
  methods: {
    onVerify(token, ekey) {
      this.verified = true;
      this.token = token;
      this.eKey = ekey;
    },
    onExpire() {
      this.verified = false;
      this.token = null;
      this.eKey = null;
      this.expired = true;
    },
    onError(err) {
      this.token = null;
      this.eKey = null;
      this.error = err;
    },
    doSave() {
      this.registerAttempts++;

      if (this.user != null && this.agreed) {
        this.isRequestInProgress = true;
        this.errorMessage = "";

        let callback = (err, data) => {
          if (err) {
            this.isRequestInProgress = false;
            this.registerAttempts += 1;
            this.errorMessage = "error-messages.errorOccurred";
          } else {
            this.$router.push("/registrationResult");
          }
        };

        ApiService.POST(
          "/users/register",
          {
            callbacktoken: this.token,
            ekey: this.ekey,
            user: this.user
          },
          callback
        );
      }
    }
  }
};
</script>
