<template>
  <div class="opp-detail white-box" :class="{ 'opp-detail--full': mode == 'full' }">
    <div class="opp-detail__header panel-heading">
      <div class="opp-detail__title">
        <span class="opp-detail__brand">{{
            opportunity.brand || $t("opportunity.brand")
          }}</span>
        <span class="opp-detail__model">
          {{ opportunity.model || $t("opportunity.model") }}</span
        >
        <span class="opp-detail__year">
          ({{ opportunity.year || $t("opportunity.yearModel") }})</span
        >
      </div>
      <div class="opp-detail__price">
        {{
          opportunity.currency === "CRC" ? "₡" : "$"
        }}{{ opportunity.price | currency }}
      </div>
    </div>
    <div class="panel-body">
      <div class="tcarousel">
        <div class="tcarousel-wrap" v-on:scroll.passive="onScroll($event)">
          <div
              v-for="imgid in imgUrls"
              :key="imgid"
              class="tcarousel-wrap-item"
          >
            <span>
              <img
                  alt=""
                  class="vehicle-image"
                  loading="lazy"
                  :src="imgid"
                  @error="imageLoadOnError"
              />
            </span>
          </div>
        </div>
        <button
            v-if="count > 1"
            :disabled="current === 0"
            @click="prevImg($event)"
            class="tcarousel-prev"
            style="display: none;"
        >
          <img alt="prev" src="@/assets/icons/prev-min.svg"/>
        </button>
        <button
            v-if="count > 1"
            :disabled="current === count - 1"
            @click="nextImg($event)"
            class="tcarousel-next"
            style="display: none;"
        >
          <img alt="next" src="@/assets/icons/next-min.svg"/>
        </button>
        <div v-if="count > 1" class="tcarousel-nav">
          <div
              v-for="(imgid, dotIndex) in imgUrls"
              :key="imgid"
              class="tcarousel-dot"
              :class="{ 'selected-dot': current === dotIndex }"
          ></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-md-6">
          <h3>{{ $t("opportunity.descriptionCar") }}</h3>
          <div class="table-responsive">
            <table class="table table-hover" aria-hidden="true">
              <tbody>
              <tr>
                <th scope="col">{{ $t("opportunity.model") }}:</th>
                <td>
                  {{ opportunity.brand }} {{ opportunity.model }} ({{
                    opportunity.year
                  }})
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.price") }}:</th>
                <td>
                  {{
                    opportunity.currency === "CRC" ? "₡" : "$"
                  }}{{ opportunity.price | currency }}
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.transferPrice") }}:</th>
                <td>
                  {{
                    opportunity.currency === "CRC" ? "₡" : "$"
                  }}{{ opportunity.transferPrice | currency }}
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.style") }}:</th>
                <td>{{ $t("carStyles." + opportunity.style) }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.fuelType") }}:</th>
                <td>
                  {{ $t("fuelTypes." + opportunity.fuelType.toLowerCase()) }}
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.transmission") }}:</th>
                <td>
                  {{
                    $t(
                        "transmissionTypes." +
                        opportunity.transmissionType.toLowerCase()
                    )
                  }}
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.motorSizeCC") }}:</th>
                <td>{{ opportunity.motorSizeCC | currency }} cc</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.mileage") }}:</th>
                <td>{{ opportunity.mileage | currency }} {{
                    $t("opportunity.mileageUnit." + opportunity.mileageUnit)
                  }}
                </td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.exteriorColor") }}:</th>
                <td>{{ opportunity.exteriorColor }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.interiorColor") }}:</th>
                <td>{{ opportunity.interiorColor }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.payTaxes") }}:</th>
                <td>
                  {{
                    opportunity.payTaxes
                        ? $t("commons.yes")
                        : $t("commons.no")
                  }}
                </td>
              </tr>
              <tr v-if="isAdmin">
                <th scope="col">{{ $t("opportunity.carIdNumber") }}:</th>
                <td>{{ opportunity.carIdNumber }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.passengersNumber") }}:</th>
                <td>{{ opportunity.passengersNumber }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.province") }}:</th>
                <td>
                  {{ opportunity.province }}
                  <span v-if="opportunity.canton">
                      ({{ opportunity.canton }})
                    </span>
                </td>
              </tr>
              <tr v-if="opportunity.knownDamage">
                <th scope="col">{{ $t("opportunity.knownDamage") }}:</th>
                <td>{{ opportunity.knownDamage }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("opportunity.views") }}:</th>
                <td>{{ tallyCount }}</td>
              </tr>
              <tr v-if="isAdmin">
                <th scope="col">{{ $t("commons.dateCreated") }}:</th>
                <td>{{ formattedDate(opportunity.dateCreated) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-xs-12 col-md-6">
          <div v-if="contact" class="opp-detail__contact">
            <h3>{{ $t("opportunity.contactInfo") }}</h3>
            <table aria-hidden="true">
              <tr>
                <th v-if="contact.merchantName" scope="col">{{ $t("user.merchant") }}:</th>
                <td>{{ contact.merchantName }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("user.name") }}:</th>
                <td>{{ contact.fullname }}</td>
              </tr>
              <tr>
                <th scope="col">{{ $t("user.primaryPhone") }}:</th>
                <td>
                  <template v-if="displayPhones">
                    <button @click="onClickDisplayPhones()" type="button" class="btn btn-outline-primary">
                      {{ $t("opportunity.viewPhones") }}
                    </button>
                  </template>
                  <template v-else>
                    <span v-for="p in phoneList" :key="p.phone">
                    <a :href="contactSellerByWhatsapp(p.phone)" target="_blank">{{ p.phone }}&nbsp;</a>
                    <span>{{ ' ' }}</span>
                  </span>
                  </template>
                </td>
              </tr>
              <tr>
                <th scope="col"></th>
                <td>
                  <a id="ccmc_detail_whatsappShare" class="btn btn-whatsapp" @click="contactSellerByWhatsappAnalytics()"
                     :href="contactSellerByWhatsapp(getWhatsappNumber)" target="_blank" role="button">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                      <path
                          d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                    </svg>
                    {{ $t('opportunity.contactSeller') }}
                  </a>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div class="row mt-2 mb-2">
              <div class="col-auto">
                <a id="ccmc_detail_facebookShare" :href="shareFacebookUrl" target="_blank" class="btn-share--facebook" style="text-decoration:none;"
                   role="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share"
                       viewBox="0 0 16 16">
                    <path
                        d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
                  </svg>
                  {{ $t("commons.facebook") }} </a>

              </div>
              <div class="col-auto">
                <a id="cccm_detail_whatsappShare" :href="shareWhatsappUrl" target="_blank" class="btn-share--whatsapp" style="text-decoration:none;"
                   role="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share"
                       viewBox="0 0 16 16">
                    <path
                        d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.5 2.5 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5m-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3"/>
                  </svg>
                  {{ $t("commons.whatsapp") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="opportunity.extraList && opportunity.extraList.length" class="mb-4">
        <h3 class="mb-3">{{ $t("opportunity.vehicleExtras") }}</h3>
        <extras :preSelectedExtras="opportunity.extraList"/>
      </div>
      <template v-if="isLogged">

      </template>
    </div>
    <div>
      <div class="row justify-content-center mb-2">
        <div class="col-auto">
          <button
              class="btn btn-success"
              v-if="isEditable && !isImpersonalizator"
              type="button"
              @click="sendToEdit()"
          >
            {{ $t("commons.edit") }}
          </button>
        </div>
      </div>
      <div v-if="isOnMyWishList" class="row justify-content-center mb-2">
        <div class="col-auto">
          <button
              class="btn btn-danger"
              type="button"
              @click="removeFromMyList()"
          >
            {{ $t("commons.removeFromList") }}
          </button>
        </div>
      </div>
      <div v-if="!isImpersonalizator" class="row justify-content-center mb-2">
        <div class="col-auto">
          <button
              id="ccmc_detail_addFavorites"
              class="btn btn-success"
              type="button"
              @click="saveToMyList()"
          >
            {{ $t("opportunity.addToFavorites") }}
          </button>
        </div>
      </div>
      <div class="row justify-content-center mb-2">
        <div class="col-auto">
          <button id="ccmc_detail_allSellerAdvertisement" class="btn btn-warning" type="button" @click="showSellerOpportunities()">
            {{ $t("opportunity.sellerOpportunities") }}
          </button>
        </div>
      </div>
      <div class="row justify-content-center mb-2">
        <div class="col-auto">
          <button class="btn btn-whatsapp" type="button" @click="backToList()">
            {{ $t("commons.back") }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="complaintStep === 'step1'">
      <div class="col-xs-12 opp-detail__report">
        <a href="#" @click.prevent="complaintStep = 'step2'">{{
            $t("opportunity.reportAd")
          }}</a>
      </div>
    </div>
    <div v-else-if="complaintStep === 'step2'">
      <h3>{{ $t("opportunity.reportAdTitle") }}</h3>
      <p>{{ $t("opportunity.reportAdDescription") }}</p>
      <div class="mb-3">
        <label for="reportAdReason" class="form-label">{{
            $t("opportunity.reportAdLabel")
          }}</label>
        <input
            type="text"
            class="form-control"
            id="reportAdReason"
            v-model="complaintReason"
        />
      </div>
      <div class="opp-detail__actions">
        <button
            class="btn btn-danger"
            type="button"
            @click.prevent="reportAd()"
        >
          {{ $t("opportunity.reportAdReport") }}
        </button>
        <button
            class="btn btn-outline-danger"
            type="button"
            @click.prevent="complaintStep = 'step1'"
        >
          {{ $t("commons.cancel") }}
        </button>
      </div>
    </div>
    <div v-else-if="complaintStep === 'step3'">
      {{ $t("opportunity.reportAdThanks") }}
    </div>
  </div>
</template>

<script>
import {ApiService} from "@/rest.js";
import Extras from '@/components/opportunity/Extras.vue';
import defaultImage from "@/assets/preview.jpg";
import {mapGetters} from "vuex";
import {formatIso8601ToShorTime, buildWhatsappPreviewUrl} from "@/helper";
import {i18n} from "@/main";
import {AnalitycsData} from "@/entities";
import {ANALITYCS_TYPES} from "@/constants";

export default {
  name: "OpportunityDetail",
  components: {
    Extras
  },
  props: {
    opportunity: Object,
    mode: {
      type: String,
      default: "panel" // or 'full'
    },
    isEditable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contact: null,
      hadError: false,
      isOnMyWishList: false,
      current: 0,
      swipeEnabled: true,
      tallyCount: 0,
      complaintStep: "step1",
      complaintReason: "",
      displayPhones: true
    };
  },
  computed: {
    ...mapGetters(["isLogged", "isAdmin", "isImpersonalizator"]),
    fullExtraList() {
      if (this.opportunity && this.opportunity.extraList) {
        return this.opportunity.extraList.split(",");
      }

      return [];
    },
    transmissionKey() {
      let key = "manual";

      if (this.opportunity && this.opportunity.transmissionType) {
        key = this.opportunity.transmissionType.toLowerCase();
      }

      return "transmissionTypes." + key;
    },
    fuelKey() {
      let key = "gasoline";

      if (this.opportunity && this.opportunity.fuelType) {
        key = this.opportunity.fuelType.toLowerCase();
      }

      return "fuelTypes." + key;
    },
    imgUrls() {
      if (this.hadError) {
        return [defaultImage];
      }

      if (
          this.opportunity.attachmentIds &&
          this.opportunity.attachmentIds.length
      ) {
        return this.opportunity.attachmentIds.split(",").map(attachId => {
          return ApiService.getImageURL(attachId, false);
        });
      }

      return [defaultImage];
    },
    phoneList() {
      const phoneList = this.opportunity.phoneList;

      if (phoneList && phoneList.length) {
        return JSON.parse(phoneList);
      }

      return [];
    },
    getWhatsappNumber() {
      const phoneList = this.opportunity.phoneList;

      for (let phone of JSON.parse(phoneList)) {
        if (phone.phoneType === "WHATSAPP") return phone.phone;
      }

      return JSON.parse(JSON.parse(phoneList)[0].phone);
    },
    count() {
      return this.imgUrls.length;
    },
    isOutstanding() {
      return this.opportunity.level !== "FREE";
    },
    isNew() {
      return !!this.opportunity.isNew;
    },
    message() {
      return this.opportunity.message || "";
    },
    shareFacebookUrl() {
      return "https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=" +
          i18n.t("domain.serverUrl") + "rest/api/v1/preview/" + this.opportunity.id +
          "&display=popup&ref=plugin&src=share_button"
    },
    shareWhatsappUrl() {
      return "https://api.whatsapp.com/send?text=Mira este anuncio en CualCarroMeCompro.com - " +
          i18n.t("domain.serverUrl") + "rest/api/v1/preview/" + this.opportunity.id + "?thumb=true";
    }
  },
  methods: {
    contactSellerByWhatsapp(phone) {
      return buildWhatsappPreviewUrl(phone, i18n.t("opportunity.sendWhatsAppMessage"), this.opportunity, i18n.t("domain.serverUrl"));
    },
    contactSellerByWhatsappAnalytics() {
      const analyticsData = new AnalitycsData();

      analyticsData.opportunityId = this.opportunity.id;
      analyticsData.clickType = ANALITYCS_TYPES.CONTACT_SELLER_BY_WHATSAPP.value;

      ApiService.POST(
          "/analytics/contactByWhatsapp",
          analyticsData,
          () => {}
      );
    },
    onScroll($e) {
      if (this.swipeEnabled) {
        const wrapper = $e.target
            .closest(".tcarousel")
            .getElementsByClassName("tcarousel-wrap")[0];
        const imgWidth = wrapper.offsetWidth;
        const scroll = wrapper.scrollLeft;
        this.current = Math.floor(scroll / imgWidth);
      }
    },
    prevImg($e) {
      const wrapper = $e.target
          .closest(".tcarousel")
          .getElementsByClassName("tcarousel-wrap")[0];
      const imgWidth = wrapper.offsetWidth;
      this.swipeEnabled = false;
      this.current -= 1;
      wrapper.scrollLeft = this.current * imgWidth;
    },
    nextImg($e) {
      const wrapper = $e.target
          .closest(".tcarousel")
          .getElementsByClassName("tcarousel-wrap")[0];
      const imgWidth = wrapper.offsetWidth;
      this.swipeEnabled = false;
      this.current += 1;
      wrapper.scrollLeft = this.current * imgWidth;
    },
    imageLoadOnError() {
      this.hadError = true;
    },
    backToList() {
      this.$router.go(-1);

      // Set timeout as it uses smooth scroll
      setTimeout(() => {
        document.documentElement.scrollTop = localStorage.getItem("scroll");
      }, 100);
    },
    showSellerOpportunities() {
      this.$router.push({
        name: 'sellerOpportunitiesView',
        params: {
          sellerId: this.opportunity.userId
        }
      })
    },
    saveToMyList() {
      ApiService.POST(
          "/users/protected/myWishList",
          this.opportunity,
          (err, apiResponse) => {
            if (!err) {
              this.isOnMyWishList = true;
            }
          }
      );
    },
    removeFromMyList() {
      ApiService.DELETE(
          "/users/protected/myWishList",
          this.opportunity,
          (err, apiResponse) => {
            if (!err) {
              this.isOnMyWishList = false;
            }
          }
      );
    },
    reportAd() {
      this.complaintStep = "step3";

      ApiService.POST("/complaint", {
        opportunityId: this.opportunity.id,
        details: this.complaintReason
      });

      setTimeout(() => {
        // No link shown
        this.complaintStep = "step4";
      }, 3000);
    },
    sendToEdit() {
      this.$router.push({
        name: 'opportunityUpdateView',
        params: {opportunityId: this.opportunity.id}
      })
    },
    formattedDate(creationDateTime) {
      return formatIso8601ToShorTime(creationDateTime);
    },
    onClickDisplayPhones() {
      this.displayPhones = !this.displayPhones;
    }
  },
  mounted() {
    ApiService.GET(
        `/opportunity/${this.opportunity.id}/contact`,
        (err, apiResponse) => {
          if (!err) {
            this.contact = apiResponse;
          }
        }
    );

    ApiService.GET(`/tally/${this.opportunity.id}/increment`);

    ApiService.GET(
        `/tally/${this.opportunity.id}/count`,
        (err, apiResponse) => {
          if (!err) {
            this.tallyCount = apiResponse;
          }
        }
    );

  }
};
</script>
