<template>
<fragment>
  <div class="row">
    <div class="col-12 col-lg-6" :class="{ 'has-error': userTypesError }">
      <select id="userType" class="ccmc_input form-control"
        :value="newUser.userType" v-model="newUser.userType">
        <option v-for="type of userTypes" :key="type.value" :value="type.value">{{ $t(type.label) }}</option>
      </select>
      <span v-if="userTypesError" class="help-block">{{ $t(userTypesError) }}</span>
    </div>
    <div v-show="isMerchant" class="col-12 col-lg-6" :class="{ 'has-error': merchantNameError }">
      <input type="text" id="merchantName" class="ccmc_input form-control" :placeholder=" $t('user.merchantName')" v-model="newUser.merchantName"/>
      <span v-if="merchantNameError" class="help-block">{{ $t(merchantNameError, { value: newUser.merchantName }) }}</span>
    </div>
  </div>
  <div v-show="isMerchant">
    <locations-costa-rica
        :locationsCostaRica="locationsCR"
        :attempts="attempts"
        @locations-CR="handleLocationsCr($event)"
    ></locations-costa-rica>
  </div>
  <div class="row">
    <div class="col-12 col-lg-4" :class="{ 'has-error': nameError }">
      <input type="text" id="name" class="ccmc_input form-control" :placeholder="$t(getNameLabel)" v-model="newUser.name"/>
      <span v-if="nameError" class="help-block">{{ $t(nameError, { value: newUser.name }) }}</span>
    </div>
    <div class="col-12 col-lg-4">
      <input type="text" id="lastName" class="ccmc_input form-control" :placeholder="$t(getLastNameLabel)" v-model="newUser.lastName"/>
    </div>
    <div class="col-12 col-lg-4">
      <input type="text" id="lastName2" class="ccmc_input form-control" :placeholder="$t(getSecondLastNameLabel)" v-model="newUser.lastName2"/>
    </div>
  </div>
  <phones
      :phoneList="newUser.phoneList"
      :attempts="attempts"
      @phone-list="newUser.phoneList = $event">
  </phones>
  <div class="row">
    <div class="col col-lg-4" :class="{ 'has-error': emailError }">
      <input
          type="text"
          id="email"
          class="ccmc_input form-control"
          v-model="newUser.email"
          :disabled="disableEmail && !isAdmin"
          maxlength="64"
          :placeholder="$t('commons.email')"
      />
      <span v-if="emailError" class="help-block">{{ $t(emailError, {value: newUser.email}) }}</span>
    </div>
    <div class="col col-lg-4" v-show="isEditing">
      <password-input
          v-model="newUser.password"
          input-id="password"
          :autocomplete="'on'"
          :placeholder="$t(getPasswordFieldText)"
      >
      </password-input>
      <span v-if="passwordError" class="help-block">{{ $t(passwordError) }}</span>
    </div>
    <div class="col col-lg-4" v-show="isEditing" :class="{ 'has-error': passwordConfirmationError }">
        <password-input
            v-model="passwordConfirmation"
            input-id="password-confirmation"
            :autocomplete="'on'"
            :placeholder="$t(getConfirmationPasswordFieldText)"
        ></password-input>
        <span v-if="passwordConfirmationError" class="help-block">{{ $t(passwordConfirmationError) }}</span>
      </div>
    </div>
</fragment>
</template>
<script>
import {LocationsCR, User} from '@/entities.js'
import {isEmpty, validEmail, validMerchantName, validName, validPasswordLight} from '@/validations'
import Loading from '@/components/utilities/Loading.vue'
import LocationsCostaRica from '@/components/utilities/LocationsCostaRica.vue'
import Phones from '@/components/utilities/Phones.vue';
import {AVAILABLE_LOCALES} from '@/languages.js'
import PasswordInput from "@/components/user/PasswordInput.vue";
import {mapGetters} from "vuex";

let availableUserTypes = [
    {"value": "PERSON", "label": "sellerTypes.individual"},
    {"value": "CARS_SELLER_MERCHANT", "label": "sellerTypes.carsSellerMerchant"}
  ];

  export default {
    name: 'UserData',
    components: {
      PasswordInput,
      Loading,
      LocationsCostaRica,
      Phones
    },
    props: {
      user: {
        type: Object,
        default: () => new User()
      },
      attempts: {
        type: Number,
        default: 0
      },
      disableEmail:{
        type: Boolean,
        default: false
      },
      isEditing:{
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        userTypes: availableUserTypes,
        newUser: this.user !== undefined ? this.user : new User(),
        locationsCR: new LocationsCR({ province : this.user.province, canton : this.user.canton, district : this.user.district }),
        availableLocales: AVAILABLE_LOCALES,
        passwordConfirmation: '',
      }
    },
    computed: {
      ...mapGetters(["isAdmin"]),
      userTypesError() {
        if (this.attempts > 0)
          if (isEmpty(this.newUser.userType)) return 'error-messages.invalidUserType'
      },
      nameError() {
        if (this.attempts > 0) {
          if (!validName(this.newUser.name)) return 'error-messages.invalidNameUser'
        }

        return ''
      },
      merchantNameError() {
        if (this.attempts > 0) {
          if (!validMerchantName(this.newUser.merchantName)) return 'error-messages.invalidMerchantNameUser'
        }

        return ''
      },
      idTypeError() {
        if (this.attempts > 0 && isEmpty(this.newUser.idType)) {
          return 'error-messages.invalidIdType'
      }
        return ''
      },
      isMerchant() {
          return this.newUser.userType === 'CARS_SELLER_MERCHANT'
      },
      getNameLabel() {
          return this.isMerchant ? 'user.contactName' : 'user.name'
      },
      getLastNameLabel() {
          return this.isMerchant ? 'user.contactLastName' : 'user.lastName'
      },
      getSecondLastNameLabel() {
          return this.isMerchant ? 'user.contactLastName2' : 'user.secondLastName'
      },
      getPasswordFieldText() {
        return this.isEditing ? 'registration.newPassword' : 'registration.currentPassword'
      },
      getConfirmationPasswordFieldText() {
        return this.isUpdatingCredentials ? 'registration.confirmNewPasword' : 'registration.newPasswordConf'
      },
      emailError() {
        if (this.attempts > 0) {
          if (!validEmail(this.newUser.email)) return 'error-messages.invalidEmail'
        }

        return ''
      },
      passwordError() {
        if (this.attempts > 0) {
          if (validPasswordLight(this.newUser.password).strength === 0)
            return "error-messages.invalidPassword";
        }
      },
      passwordConfirmationError() {
        if (this.attempts > 0) {
          if (this.newUser.password !== this.passwordConfirmation)
            return "error-messages.invalidPasswordConfirm";
        }
      }
    },
    watch: {
      newUser: {
        deep: true,
        handler() {
          this.emitUserData();
        }
      }
    },
    methods: {
      emitUserData() {
        if (this.validateUserForm()) {
          this.$emit('user-data', this.newUser)
        } else {
          this.$emit('user-data', null)
        }
      },
      handleLocationsCr(locationsCostaRica) {
        this.newUser.province = locationsCostaRica.province;
        this.newUser.canton = locationsCostaRica.canton;
        this.newUser.district = locationsCostaRica.district;
        this.emitUserData();
      },
      validateUserForm() {
        if (isEmpty(this.newUser.userType)) return false
        if (this.newUser.userType === this.userTypes[1].value){
          if (!validMerchantName(this.newUser.merchantName)) return false
        }
        if (!validName(this.newUser.name)) return false
        if (!this.newUser.phoneList || this.newUser.phoneList.length === 0) return false
        if (this.newUser.password !== this.passwordConfirmation) return false
        return validPasswordLight(this.newUser.password).strength !== 0;


      },
    }, mounted(){
      this.newUser.language = this.$store.getters.locale;
    }
  }
</script>
