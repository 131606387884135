<template>
  <fragment>
    <div class="row">
      <div class="col col-lg-4 ">
        <div :class="{ 'has-error': provinceError }">
          <select
              id="province"
              class="ccmc_input form-control"
              v-model="locationsCrProxy.province"
              @change="onProvinceChange()"
              :title="$t('opportunity.province')"
          >
            <option value="" disabled selected>{{ $t("opportunity.province") }}</option>
            <option
                v-for="province in provinceList"
                :key="province"
                :value="province"
            >{{ province }}
            </option
            >
          </select>
        </div>
      </div>
      <div class="col col-lg-4 ">
        <div :class="{ 'has-error': cantonError }">
          <select
              id="canton"
              class="ccmc_input form-control"
              v-model="locationsCrProxy.canton"
              @change="onCantonChange()"
              :title="$t('opportunity.canton')"
          >
            <option value="" disabled selected>{{ $t("opportunity.canton") }}</option>
            <option v-for="canton in cantonList" :key="canton" :value="canton">{{
                canton
              }}
            </option>
          </select>
        </div>
      </div>
      <div class="col col-lg-4 ">
        <div :class="{ 'has-error': distritoError }">
          <select
              id="distrito"
              class="ccmc_input form-control"
              v-model="locationsCrProxy.district"
              :title="$t('opportunity.district')"
          >
            <option value="" disabled selected>{{ $t("opportunity.district") }}</option>
            <option
                v-for="distrito in distritoList"
                :key="distrito"
                :value="distrito"
            >{{ distrito }}
            </option
            >
          </select>
        </div>
      </div>
    </div>
  </fragment>
</template>
<script>
import {isEmpty} from "@/validations";
import {LocationsCR} from "@/entities";
import {provincias} from "./provincias";

export const getProvincias = () => {
  return provincias.map(p => p.nombre).sort();
};

export const getCantones = provincia => {
  if (provincia) {
    const temp = provincias.find(p => p.nombre === provincia);

    if (temp) {
      return temp.cantones.map(c => c.nombre).sort();
    }
  }

  return [];
};

export const getDistritos = (provincia, canton) => {
  let temp = provincias.find(p => p.nombre === provincia);

  if (temp) {
    temp = temp.cantones.find(c => c.nombre === canton);
  }

  if (temp) {
    return temp.distritos.slice().sort();
  }

  return temp !== undefined ? temp.sort : temp

};

export default {
  name: "LocationsCostaRica",
  props: {
    locationsCostaRica: {
      type: Object,
      default: () => new LocationsCR()
    },
    attempts: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      locationsCrProxy: this.locationsCostaRica,
      provinceList: getProvincias(),
      cantonList: [],
      distritoList: []
    };
  },
  methods: {
    emitLocationsCR() {
      this.$emit("locations-CR", this.locationsCrProxy);
    },
    onProvinceChange() {
      this.locationsCrProxy.canton = "";
      this.loadCantones();
    },
    onCantonChange() {
      this.locationsCrProxy.district = "";
      this.loadDistritos();
    },
    loadCantones() {
      this.cantonList = getCantones(this.locationsCrProxy.province);
      this.loadDistritos();
    },
    loadDistritos() {
      this.distritoList = getDistritos(
          this.locationsCrProxy.province,
          this.locationsCrProxy.canton
      );
    }
  },
  computed: {
    provinceError() {
      return "";
    },
    cantonError() {
      return "";
    },
    distritoError() {
      return "";
    }
  },
  watch: {
    locationsCostaRica: {
      deep: true,
      handler() {
        this.locationsCrProxy = this.locationsCostaRica;
        this.loadCantones();
        this.loadDistritos();
      }
    },
    locationsCrProxy: {
      deep: true,
      handler() {
        this.emitLocationsCR();
      }
    }
  },
  mounted() {
    if (!isEmpty(this.locationsCrProxy.province)) {
      this.loadCantones();
      this.loadDistritos();
    }
  }
};
</script>
