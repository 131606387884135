<template>
  <div class="white-box">
    <div class="row">
      <div
          v-for="category of extrasCategories"
          :key="category.categoryName"
          class="col"
      >
        <div class="sec-header text-center mb-2 pb-1">
          <strong>{{
              category.name
            }}</strong>
        </div>
        <div
            v-for="extra of category.extras"
            :key="extra.key"
            :class="{'form-check': editing }"
        >
          <template v-if="editing">
            <input
                class="form-check-input"
                type="checkbox"
                :value="extra.key"
                :id="extra.key"
                v-model="selectedExtras"
            />
            <label class="form-check-label" :for="extra.key">
              {{ extra.label }}
            </label>
          </template>
          <template v-else-if="isChecked(extra.key)">
            <em class="bi bi-check-circle" aria-hidden="true"></em>
            {{" "}}
            <span>{{ $t("opportunity.extras." + extra.key) }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let extrasCategoriesKeys = [
  {
    categoryName: "confort",
    extras: [
      {"key": "AIR_CONDITIONING", "defaultChecked": true},
      {"key": "ADJUSTABLE_STEERING_WHEEL", "defaultChecked": true},
      {"key": "CRUISE_CONTROL", "defaultChecked": false},
      {"key": "ELECTRIC_GLASSES", "defaultChecked": true},
      {"key": "ELECTRIC_MIRRORS", "defaultChecked": true},
      {"key": "ELECTRONIC_STABILITY", "defaultChecked": true},
      {"key": "HYDRAULIC_STEERING", "defaultChecked": true},
      {"key": "LEATHER_UPHOLSTERY", "defaultChecked": false},
      {"key": "LEATHER_UPHOLSTERY_CLOTH", "defaultChecked": false},
      {"key": "MEMORY_SEAT", "defaultChecked": false},
      {"key": "MULTIFUNCTION_STEERING", "defaultChecked": true},
      {"key": "RUBBER_MAT", "defaultChecked": false},
      {"key": "STEERING_WHEEL_CONTROL", "defaultChecked": true},
      {"key": "STEERING_WHEEL_GEARSHIFT", "defaultChecked": false},
      {"key": "TRAVEL_COMPUTER", "defaultChecked": true},
      {"key": "DUAL_GEARBOX", "defaultChecked": false}
    ]
  },
  {
    categoryName: "segurity",
    extras: [
      {"key": "ABS_BRAKES", "defaultChecked": false},
      {"key": "AIRBAGS", "defaultChecked": true},
      {"key": "ALARM", "defaultChecked": false},
      {"key": "CENTRAL_LOCKING", "defaultChecked": true},
      {"key": "SMART_KEY", "defaultChecked": false},
      {"key": "TIRE_PRESSURE_MONITOR", "defaultChecked": false},
      {"key": "TURBO", "defaultChecked": false},
      {"key": "POLARIZED_GLASSES", "defaultChecked": true},
      {"key": "POWER_SEATS", "defaultChecked": false}
    ]
  },
  {
    categoryName: "exterior",
    extras: [
      {"key": "DIRECTIONAL_MIRROR", "defaultChecked": false},
      {"key": "LUGGAGE_COVER", "defaultChecked": false},
      {"key": "LUXURY_RINGS", "defaultChecked": true},
      {"key": "FRONT_SENSORS", "defaultChecked": false},
      {"key": "HALOGEN", "defaultChecked": true},
      {"key": "RAIN_SENSOR", "defaultChecked": false},
      {"key": "RACKS", "defaultChecked": false},
      {"key": "REAR_DEFROSTER", "defaultChecked": true},
      {"key": "REVERSE_CAMERA", "defaultChecked": true},
      {"key": "REVERSE_SENSORS", "defaultChecked": true},
      {"key": "ROOF_BASKET", "defaultChecked": false},
      {"key": "ROOF_RAILS", "defaultChecked": false},
      {"key": "SELF_RETRACTING_MIRRORS", "defaultChecked": true},
      {"key": "SUNROOF", "defaultChecked": false},
      {"key": "XENON_LIGHTS", "defaultChecked": true}
    ]
  },
  {
    categoryName: "audioVideo",
    extras: [
      {"key": "DASH_SCREEN", "defaultChecked": true},
      {"key": "DVD_SCREEN", "defaultChecked": false},
      {"key": "RADIO_WITH_CASSETTE", "defaultChecked": false},
      {"key": "RADIO_WITH_CD", "defaultChecked": false},
      {"key": "RADIO_WITH_USB", "defaultChecked": false},
    ]
  }
];

import {Extra, ExtraCategory} from "@/entities";
import {GlobalEventEmitter} from '@/main.js'

export default {
  name: "Extras",
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    preSelectedExtras: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      extrasCategories: [],
      selectedExtras:
          this.preSelectedExtras && this.preSelectedExtras.length > 0
              ? this.preSelectedExtras.split(",").sort()
              : []
    };
  },
  methods: {
    isChecked(extraKey) {
      return this.selectedExtras.includes(extraKey);
    },
    compareExtra(extra1, extra2) {
      return extra1.label.localeCompare(extra2.label);
    },
    loadExtrasLabels() {
      this.extrasCategories = [];
      extrasCategoriesKeys.forEach((extraCategoryRepresentation) => {
        const extraCategory = new ExtraCategory()
        extraCategory.name = this.$i18n.t("opportunity.extrasCategory." + extraCategoryRepresentation.categoryName)
        let extras = [];

        extraCategoryRepresentation.extras.forEach(extraRepresentation => {
          let extra = new Extra()
          extra.label = this.$i18n.t("opportunity.extras." + extraRepresentation.key)
          extra.key = extraRepresentation.key;
          extra.isChecked = extraRepresentation.defaultChecked;

          extras.push(extra)
        });

        extraCategory.extras = extras.sort(
            (a, b) => {
              if (a.label > b.label) return 1;
              if (a.label < b.label) return -1;
              return 0
            });

        this.extrasCategories.push(extraCategory)
      });
    },
    getCheckedExtras() {
      const checkedKeys = [];

      extrasCategoriesKeys.forEach(category => {
          category.extras.forEach(extra => {
          if (extra.defaultChecked) {
            checkedKeys.push(extra.key);
          }
        });
      });

      this.selectedExtras = checkedKeys;
    }
  },
  watch: {
    selectedExtras: function () {
      this.$emit("extra-selected", this.selectedExtras.toString());
    }
  },
  mounted() {
    this.loadExtrasLabels();
    this.getCheckedExtras();
  },
  created() {
    GlobalEventEmitter.$on('localeChanged', () => {
      this.loadExtrasLabels();
    })
  },
  beforeDestroy() {
    GlobalEventEmitter.$off('localeChanged', () => {})
  },
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";

.sec-header {
  border-bottom: 2px solid $border-color;
  margin-top: -4px;
}
</style>